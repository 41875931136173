import { useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { wrapUseRoutes } from "@sentry/react";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

// redux
import { validateToken } from "./store/slices/userSlice";
// layouts
import PreAuthWrapper from "./containers/PreAuthWrapper";
import AuthWrapper from "./containers/AuthWrapper";
import AuthWrapperNoMenu from "./containers/AuthWrapperNoMenu";
// import NotFound from './components/User/Page404';
// pages
import Application from "./components/Application";
import ApplicationsList from "./components/Application/ApplicationsList";
import Organisation from "./components/Register/Organisation";
// import QuotesList from "./components/User/QuotesList";
import Login from "./components/User/Login";
import Register from "./components/Register";
import NotFound from "./pages/NotFound";
import CustomerList from "./pages/CustomerList";
// import AcceptRequestToJoin from "./components/User/AcceptRequestToJoin";
import InviteUsers from "./components/Register/InviteUsers";
import ForgotPassword from "./components/User/ForgotPassword";
import ResetPassword from "./components/User/ResetPassword";
import UserProfile from "./components/User/UserProfile";
// import UserSettings from "./components/User/UserSettings";
import Settings from "./components/Settings";
import OrganisationProfile from "./components/Organisation/OrganisationProfile";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import UserList from "./components/User/UserList";
import Billing from "./components/Billing";
import QuotesList from "./components/Quote/QuoteList";
import Quote from "./components/Quote";
import ContactDetails from "./pages/ContactDetails";
import Calculator from "./components/Calculator";
import CalculatorList from "./components/Calculator/CalculatorList";
import { PLATFORM_NAME, REACT_APP_PLATFORM_NAME } from "./constants";

// import ConfirmEmail from "./components/User/Register/ConfirmPhone";

// ----------------------------------------------------------------------

function handleDecode(token) {
  if (token && token.length > 12) {
    const now = Date.now() / 1000;
    const decoded = jwtDecode(token);
    // console.log("decoded", decoded.exp);
    // console.log("now", now);

    // return decoded.exp < now;
    return {
      isTokenExpired: decoded.exp < now,
      isCustomerLogin: decoded?.loginType ? decoded?.loginType === "customer" : false,
    };
  }

  return true;
}

export default function Router(props) {
  const token = localStorage.token;
  const sessionToken = sessionStorage.token;
  const tokenDecoded = handleDecode(token);
  // const isTokenExpired = false;
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const userState = useSelector(userSelector);
  // const organisation = useSelector(organisationSelector);

  const user = localStorage.user;

  async function handleValidateToken(t) {
    if (REACT_APP_PLATFORM_NAME === PLATFORM_NAME.CONNECTIVE) {
      if (t && sessionToken && !tokenDecoded?.isCustomerLogin) {
        await dispatch(validateToken(t)).unwrap();
      }
      return;
    } else if (t) {
      await dispatch(validateToken(t)).unwrap();
    }
  }

  // function noToken() {
  //   if (isTokenExpired) return <PreAuthWrapper {...props} />;
  // }

  // function hasOrganisation(organisation, user) {
  //   return organisation._id || user.organisation;
  // }

  useEffect(async () => {
    handleValidateToken(token);
  }, []);

  // Wrap useRoutes with Sentry to enable Sentry to reach the router's context.
  const useSentryRoutes = wrapUseRoutes(useRoutes);

  return useSentryRoutes([
    {
      path: "/",
      element: <PreAuthWrapper {...props} />,
      children: [
        { path: "register", element: <Register /> },
        // { path: "confirm", element: <ConfirmEmail /> },
        { path: "login", element: <Login /> },
        // { path: "forgotpassword", element: <Password /> },
        // { path: "reset-password/:resetToken", element: <UpdatePassword /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "404", element: <NotFound /> },
        { path: "privacy", element: <Privacy /> },
        { path: "contact-details", element: <ContactDetails /> },
        { path: "terms-and-conditions", element: <Terms /> },
      ],
    },
    {
      path: "/",
      element: !tokenDecoded?.isTokenExpired ? <AuthWrapperNoMenu {...props} /> : <Navigate to="/" />,
      children: [
        { path: "organisation", element: <Organisation /> },
        { path: "invite-users", element: <InviteUsers /> },
      ],
    },
    {
      path: "/password",
      element: <PreAuthWrapper {...props} />,
      children: [
        { path: "forgot", element: <ForgotPassword /> },
        { path: "reset/:key", element: <ResetPassword user={user} /> },
      ],
    },
    {
      path: "/",
      element: !tokenDecoded?.isTokenExpired ? <AuthWrapper {...props} /> : <Navigate to="/" />,
      // element: !tokenDecoded?.isTokenExpired ? (
      //   hasOrganisation(organisation, userFresh) ?
      //     <AuthWrapper {...props} /> :
      //     <Navigate to="/organisation" replace  />
      // ) : (
      //   <Navigate to="/" />
      // ),
      children: [
        { path: "quotes", element: <QuotesList /> },
        // { path: "quote/", element: <Application /> },
        { path: "quote/:applicationId", element: <Quote /> },
        { path: "applications", element: <ApplicationsList /> },
        // { path: "application/", element: <Application /> },
        { path: "application/:applicationId", element: <Application /> },
        // { path: "referrals", element: <ApplicationsList /> },
        // { path: "referral/:applicationId", element: <Application /> },
        { path: "customers", element: <CustomerList /> },
        { path: "users", element: <UserList /> },
        { path: "calculator", element: <CalculatorList /> },
        { path: "calculator/:applicationId", element: <Calculator /> },
        { path: "settings/:path", element: <Settings /> },
        { path: "billing/:path", element: true ? <Billing /> : <Navigate to="/" /> },
        // children: [
        //   { path: "general",  },

        // {
        //   path: "/dashboard",
        //   element: (
        //     <Navigate to={`/dashboard`} />
        //   ),
        // },
        //   ],
        // },
      ],
    },
    {
      path: "/account",
      element: !tokenDecoded?.isTokenExpired ? <AuthWrapper {...props} /> : <Navigate to="/" />,
      children: [
        { path: "profile", element: <UserProfile /> },
        { path: "organisation", element: <OrganisationProfile /> },

        // {
        //   path: "/dashboard",
        //   element: (
        //     <Navigate to={`/dashboard`} />
        //   ),
        // },
      ],
    },

    // {
    //   path: "/request-success",
    //   element: <AcceptRequestToJoin />,
    // },
    // {
    //   path: "/request-fail",
    //   element: <AcceptRequestToJoin />,
    // },
    {
      path: "/subscribe",
      element: !tokenDecoded?.isTokenExpired ? <AuthWrapper {...props} /> : <Navigate to="/" />,
    },
    // {
    //   path: "/setup",
    //   element: <Navigate to="/" />,
    // },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
