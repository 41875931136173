import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import posthog from "posthog-js";
import { init as sentryInit, reactRouterV6BrowserTracingIntegration, replayIntegration } from "@sentry/react";
import { PostHogOptions } from "../constants";

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, PostHogOptions);

sentryInit({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    posthog.sentryIntegration({
      organization: process.env.REACT_APP_SENTRY_ORGANIZATION,
      projectId: process.env.REACT_APP_SENTRY_PROJECT_ID,
      severityAllowList: ["error"],
    }),
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^\//,
    /^https:\/\/(dev\.server\.|dev\.|devconnective\.|connective\.)api\.financeable\.com\.au/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
