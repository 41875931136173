import React from "react";
import { Stack, Typography } from "@mui/material";
import { ERRORS_MESSAGE } from "../../constants";

const Error = () => (
  <Stack alignItems="center" justifyContent="center" height="100vh">
    <Typography>{ERRORS_MESSAGE.fetchErrorMsg}</Typography>
  </Stack>
);

export { Error };
