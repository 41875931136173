import { createSlice, configureStore, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { headers, reusableFetch, reusableFormDataFetch, reusableWithoutTokenFetch } from "../../utils/useAxios";
import { LENDER_URL } from "../../socket";

// export const startApplication = createAsyncThunk(
//   "application/startApplication",
//   async ({ isQuoteApplication, applicationType }, { rejectWithValue }) => {
//     const url = isQuoteApplication
//       ? `application/start/quote/${applicationType}`
//       : `application/start/${applicationType}`;
//     const newApplication = await reusableFetch(url, "GET", null, rejectWithValue);
//     return newApplication;
//   },
// );

export const startApplication = createAsyncThunk(
  "application/startApplication",
  async ({ url }, { rejectWithValue }) => {
    const newApplication = await reusableFetch(url, "GET", null, rejectWithValue);
    return newApplication;
  },
);

export const linkToOpportunity = createAsyncThunk(
  "application/linkToOpportunity",
  async (data, { rejectWithValue }) => {
    const application = await reusableFetch(`application/link/opportunity`, "POST", data, rejectWithValue);
    return application;
  },
);

export const getApplication = createAsyncThunk(
  "userDetails/getApplication",
  async (applicationId, { getState, rejectWithValue }) => {
    const application = await reusableFetch(`application/${applicationId}`, "GET", rejectWithValue);
    // const initApp = getState()?.userDetails?.application || {};
    // const newApp = _.merge(initApp, application?.data || {})
    return application;
  },
);

export const getApplicationComments = createAsyncThunk(
  "/getApplicationComments",
  async (applicationId, { getState, rejectWithValue }) => {
    const comments = await reusableFetch(`application/${applicationId}/comment`, "GET", rejectWithValue);
    return comments;
  },
);

export const addApplicationComment = createAsyncThunk("applicationComment/add", async (data, { rejectWithValue }) => {
  const comment = await reusableFetch(`application/${data.applicationId}/comment`, "POST", data, rejectWithValue);
  return comment;
});

export const getApplicationActivityLog = createAsyncThunk(
  "/activity-log",
  async (applicationId, { getState, rejectWithValue }) => {
    const application = await reusableFetch(`activity-log/${applicationId}`, "GET", rejectWithValue);
    return application;
  },
);

export const getApplicationServiceLog = createAsyncThunk(
  "application/getApplicationServiceLog",
  async (applicationId, { getState, rejectWithValue }) => {
    const serviceLog = await reusableFetch(
      `service-usage/get-service-usage-details?applicationId=${applicationId}`,
      "GET",
      rejectWithValue,
    );
    return serviceLog?.data;
  },
);

export const updateApplication = createAsyncThunk("application/", async (data, { rejectWithValue, getState }) => {
  const application = await reusableFetch(`application/update`, "POST", data, rejectWithValue);
  return application;
});

export const updateAppStatusToWorkShop = createAsyncThunk("application/", async (data, { rejectWithValue }) => {
  const application = await reusableFetch(`application/update/quote/status`, "PUT", data, rejectWithValue);
  return application;
});

export const submitApplication = createAsyncThunk(
  "application/submitApplication",
  async (data, { rejectWithValue, getState }) => {
    const application = await reusableFetch(`application/submit`, "POST", data, rejectWithValue);
    return application;
  },
);

export const set2faCode = createAsyncThunk(
  "application/set2faCode",
  async ({ applicationId, lenderType, code, lenderName }, { rejectWithValue }) => {
    console.log("LENDER_URL ===> ", LENDER_URL);
    const application = await axios.post(
      `${LENDER_URL}/api/${lenderName.includes("angle") ? "angle" : lenderName}/2fa`,
      {
        applicationId,
        lenderType,
        code,
      },
    );
    return application;
  },
);

export const getApplicationDownloadUrl = createAsyncThunk(
  "application/url",
  async ({ applicationId }, { rejectWithValue }) => {
    const application = await reusableFetch(`application/download/${applicationId}`, "GET", rejectWithValue);
    return application;
  },
);

export const updateAsset = createAsyncThunk("asset/updateAsset", async (data, { rejectWithValue }) => {
  const asset = await reusableFetch(`asset/`, "POST", data, rejectWithValue);
  return asset.data;
});

// export const updateAssets = createAsyncThunk(
//   "assets/updateAssets",
//   async (data, { rejectWithValue }) => {
//     const assets = await reusableFetch(
//       `customer-assets`,
//       "PUT",
//       data,
//       rejectWithValue
//     );
//     return assets;
//   }
// );

export const uploadInvoice = createAsyncThunk("asset/upload", async ({ formData, assetId }, { rejectWithValue }) => {
  const asset = await reusableFormDataFetch(`asset/upload/${assetId}`, "POST", formData, rejectWithValue);
  return asset;
});

export const updateLoanDetails = createAsyncThunk("loanDetails/update", async (data, { rejectWithValue }) => {
  const loanDetails = await reusableFetch(`loanDetails/`, "POST", data, rejectWithValue);
  return loanDetails;
});

export const createEntity = createAsyncThunk("entity/create", async (data, { rejectWithValue }) => {
  const application = await reusableFetch(`entity/`, "POST", data, rejectWithValue);
  return application;
});

export const createEmployer = createAsyncThunk("employer/create", async (data, { rejectWithValue }) => {
  const application = await reusableFetch(`employer/`, "POST", data, rejectWithValue);
  return application;
});

export const saveEmployer = createAsyncThunk("employer/save", async (data, { rejectWithValue }) => {
  const application = await reusableFetch(`employer/`, "PUT", data, rejectWithValue);
  return application;
});

export const getEntity = createAsyncThunk("entity/get", async (entityId, { rejectWithValue, getState }) => {
  const entity = await reusableFetch(`entity/${entityId}`, "GET", rejectWithValue);
  return entity;
});

export const removeEntity = createAsyncThunk(
  "entity/delete",
  async ({ entityId, applicationId }, { rejectWithValue }) => {
    const entity = await reusableFetch(
      `entity/?entityId=${entityId}&applicationId=${applicationId}`,
      "DELETE",
      {},
      rejectWithValue,
    );
    return entity;
  },
);

export const getASICData = createAsyncThunk("entity/asic-data", async (data, { rejectWithValue }) => {
  const entity = await reusableFetch(`entity/asic-data`, "POST", data, rejectWithValue);
  return entity?.data;
});

export const updateEntity = createAsyncThunk("entity/update", async (data, { rejectWithValue }) => {
  const entity = await reusableFetch(`entity`, "PUT", data, rejectWithValue);
  return entity.data;
});

export const getCompanyProfile = createAsyncThunk("entity/update", async ({ ACN }, { rejectWithValue }) => {
  const entity = await reusableFetch(`entity/search?acn=${ACN}`, "GET", rejectWithValue);
  return entity;
});

export const createCustomer = createAsyncThunk("customer/create", async (data, { rejectWithValue }) => {
  const newCustomer = await reusableFetch(`customer`, "POST", data, rejectWithValue);
  return newCustomer;
});

export const getCustomer = createAsyncThunk("customer/get", async (data, { rejectWithValue }) => {
  const customer = await reusableFetch(`customer/${data}`, "POST", rejectWithValue);
  return customer;
});

export const updateCustomer = createAsyncThunk("customer/update", async (data, { rejectWithValue }) => {
  const customer = await reusableFetch(`customer/`, "PUT", data, rejectWithValue);
  return customer;
});

export const confirmCustomerData = createAsyncThunk("customer/confirmCustomerData", async (data, { rejectWithValue }) => {
  const customer = await reusableFetch(`customer/confirm/details`, "PUT", data, rejectWithValue);
  return customer;
});

export const removeCustomer = createAsyncThunk("customer/remove", async (data, { rejectWithValue }) => {
  const customer = await reusableFetch(`customer/remove`, "PUT", data, rejectWithValue);
  return customer;
});

export const startVerification = createAsyncThunk("identity-verification/start", async (data, { rejectWithValue }) => {
  const newCustomer = await reusableFetch(`identity-verification/send`, "POST", data, rejectWithValue);
  return newCustomer;
});

export const getIdentityVerification = createAsyncThunk(
  "identity-verification/get",
  async (data, { rejectWithValue, getState }) => {
    const customer = await reusableFetch(`identity-verification/search`, "POST", data, rejectWithValue);
    return customer;
  },
);

export const getDocuments = createAsyncThunk("document/get", async (data, { rejectWithValue }) => {
  const document = await reusableFetch(`document/${data}`, "GET", rejectWithValue);
  return document;
});

export const getFileUrl = createAsyncThunk("document/file", async (data, { rejectWithValue }) => {
  const url = await reusableFetch(`document/file`, "POST", data, rejectWithValue);
  return url;
});

export const getCreditFile = createAsyncThunk("credit-profile/file", async (data, { rejectWithValue }) => {
  const file = await reusableFetch(`credit-profile/file`, "POST", data, rejectWithValue);
  return file;
});

export const getIllionCreditReportFile = createAsyncThunk("credit-profile/illion/file", async (data, { rejectWithValue }) => {
  const file = await reusableFetch(`credit-profile/illion/file`, "POST", data, rejectWithValue);
  return file;
})

export const getCommercialCreditFile = createAsyncThunk(
  "credit-profile/file-commercial",
  async (data, { rejectWithValue }) => {
    const file = await reusableFetch(`credit-profile/file-commercial`, "POST", data, rejectWithValue);
    return file;
  },
);

export const getCreditProfile = createAsyncThunk(
  "credit-profile/get",
  async ({ customerId, applicationId }, { rejectWithValue, getState }) => {
    const file = await reusableFetch(`credit-profile/?cid=${customerId}&aid=${applicationId}`, "POST", rejectWithValue);
    return file;
  },
);

export const getIllionCreditProfile = createAsyncThunk("illion-credit-profile/get", 
  async ({ customerId, applicationId }, { rejectWithValue, getState }) => {
    const file = await reusableFetch(`credit-profile/illion?cid=${customerId}&aid=${applicationId}`, "POST", rejectWithValue);
    return file;
  },
);

export const sendPrivacyLink = createAsyncThunk("document/privacy/link", async (data, { rejectWithValue }) => {
  const privacy = await reusableFetch(`document/privacy/link`, "POST", data, rejectWithValue);
  return privacy;
});

export const sendBankStatementsLink = createAsyncThunk("bankStatements/link", async (data, { rejectWithValue }) => {
  const privacy = await reusableFetch(`bank-statements/link`, "POST", data, rejectWithValue);
  return privacy;
});

export const getAddress = createAsyncThunk("address/get", async (addressId, { rejectWithValue }) => {
  const address = await reusableFetch(`address/${addressId}`, "GET", rejectWithValue);
  return address;
});

export const addAddress = createAsyncThunk("address/add", async (data, { rejectWithValue }) => {
  const address = await reusableFetch(`address`, "POST", data, rejectWithValue);
  return address;
});

export const updateAddress = createAsyncThunk("address/update", async (data, { rejectWithValue }) => {
  const address = await reusableFetch(`address`, "PUT", data, rejectWithValue);
  return address.data;
});

export const deleteAddress = createAsyncThunk("address/delete", async (data, { rejectWithValue }) => {
  const address = await reusableFetch(`address`, "DELETE", data, rejectWithValue);
  return address?.data;
});

export const addPhoneNumber = createAsyncThunk("phoneNumber/add", async (data, { rejectWithValue, getState }) => {
  const customer = await reusableFetch(`phone-number`, "POST", data, rejectWithValue);
  return customer;
});

export const addConnectiveEmail = createAsyncThunk("email/addConnectiveEmail", async (data, { rejectWithValue }) => {
  const customer = await reusableFetch(`email/connective/contact/${data.email}`, "GET", null, rejectWithValue);
  return customer;
});

export const updateContactFromEmail = createAsyncThunk("email/updateContactFromEmail", async (data, { rejectWithValue }) => {
  const customer = await reusableFetch(`customer/connective/contact`, "PUT", data, rejectWithValue);
  return customer;
});

export const addEmail = createAsyncThunk("email/add", async (data, { rejectWithValue }) => {
  const customer = await reusableFetch(`email`, "POST", data, rejectWithValue);
  return customer;
});

export const saveCustomerProfile = createAsyncThunk("customerProfile/add", async (data, { rejectWithValue }) => {
  const customerProfile = await reusableFetch(`customer-profile`, "POST", data, rejectWithValue);
  return customerProfile;
});

export const updateNotes = createAsyncThunk("notes/update", async (data, { rejectWithValue }) => {
  const notes = await reusableFetch(`notes`, "POST", data, rejectWithValue);
  return notes;
});

export const updateExpenses = createAsyncThunk("expenses/update", async (data, { rejectWithValue }) => {
  const expenses = await reusableFetch(`expenses`, "PUT", data, rejectWithValue);
  return expenses;
});

export const createFinanceItem = createAsyncThunk(
  "finance-item/create",
  async ({ data, endpoint }, { rejectWithValue }) => {
    const expenses = await reusableFetch(endpoint, "POST", data, rejectWithValue);
    return expenses;
  },
);

export const deleteFinanceItem = createAsyncThunk(
  "finance-item/delete",
  async ({ data, endpoint }, { rejectWithValue }) => {
    const expenses = await reusableFetch(endpoint, "DELETE", data, rejectWithValue);
    return expenses;
  },
);

export const uploadDocument = createAsyncThunk("document/upload", async (formData, { rejectWithValue }) => {
  const token = localStorage.token;
  const sessionToken = sessionStorage.token;
  const illionSubscriberId = sessionStorage.illionSubscriberId;
  // for (var key of formData.entries()) {
  // }
  const formDataHeaders = headers(token, sessionToken, illionSubscriberId);
  formDataHeaders["Content-Type"] = `multipart/form-data; boundary=${formData._boundary}`;
  delete formDataHeaders.Accept;
  try {
    if (formData) {
      const privacy = await axios({
        url: `${process.env.REACT_APP_API_URL}/document/upload`,
        method: "POST",
        headers: formDataHeaders,
        data: formData,
        timeout: 1000 * 30, // Wait for 30 seconds
      });
      if (privacy.status === "error") {
        return rejectWithValue(privacy);
      }
      return privacy;
    }
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const updateDeclaration = createAsyncThunk("declaration/update", async (data, { rejectWithValue }) => {
  const declaration = await reusableFetch(`declaration`, "POST", data, rejectWithValue);
  return declaration;
});

export const updateSupplier = createAsyncThunk("supplier/update", async (data, { rejectWithValue }) => {
  const supplier = await reusableFetch(`supplier`, "POST", data, rejectWithValue);
  return supplier;
});

export const updateCreditProfile = createAsyncThunk(
  "credit-profile/get",
  async (data, { rejectWithValue, getState }) => {
    const file = await reusableFetch(`credit-profile`, "POST", data, rejectWithValue);
    return file;
  },
);

export const updateFinanceItem = createAsyncThunk("financeItem/update", async (data, { rejectWithValue, getState }) => {
  const financeItem = await reusableFetch(`finance-item`, "PUT", data, rejectWithValue);
  return financeItem;
});

export const submitPrivacy = createAsyncThunk("document/privacy", async (data, { rejectWithValue, getState }) => {
  const privacy = await reusableFetch(`document/privacy`, "POST", data, rejectWithValue);
  return privacy;
});

export const createLiabilities = createAsyncThunk(
  "userDetails/createLiabilities",
  async (data, { rejectWithValue }) => {
    const liabilitiesList = await reusableFetch(`liabilities`, "POST", data, rejectWithValue);
    return liabilitiesList;
  },
);

export const deleteLiabilities = createAsyncThunk(
  "userDetails/deleteLiabilities",
  async (data, { rejectWithValue }) => {
    const liabilitiesList = await reusableFetch(`liabilities`, "DELETE", data, rejectWithValue);
    return liabilitiesList;
  },
);

export const updateLiabilities = createAsyncThunk(
  "userDetails/updateLiabilities",
  async (data, { rejectWithValue }) => {
    const liabilitiesList = await reusableFetch(`liabilities`, "PUT", data, rejectWithValue);
    return liabilitiesList;
  },
);

export const createIncome = createAsyncThunk("userDetails/createIncome", async (data, { rejectWithValue }) => {
  const incomeList = await reusableFetch(`income`, "POST", data, rejectWithValue);
  return incomeList;
});

export const updateIncome = createAsyncThunk("userDetails/updateIncome", async (data, { rejectWithValue }) => {
  const incomeList = await reusableFetch(`income`, "PUT", data, rejectWithValue);
  return incomeList;
});

export const deleteIncome = createAsyncThunk("userDetails/deleteIncome", async (data, { rejectWithValue }) => {
  const incomeList = await reusableFetch(`income`, "DELETE", data, rejectWithValue);
  return incomeList;
});

export const createExpense = createAsyncThunk("userDetails/createExpense", async (data, { rejectWithValue }) => {
  const expensesList = await reusableFetch(`expenses`, "POST", data, rejectWithValue);
  return expensesList;
});

export const updateExpense = createAsyncThunk("userDetails/updateExpense", async (data, { rejectWithValue }) => {
  const expensesList = await reusableFetch(`expenses`, "PUT", data, rejectWithValue);
  return expensesList;
});

export const deleteExpense = createAsyncThunk("userDetails/deleteExpense", async (data, { rejectWithValue }) => {
  const expensesList = await reusableFetch(`expenses`, "DELETE", data, rejectWithValue);
  return expensesList;
});

export const deleteEmployer = createAsyncThunk("userDetails/deleteEmployer", async (data, { rejectWithValue }) => {
  const employersList = await reusableFetch(`employer`, "DELETE", data, rejectWithValue);
  return employersList;
});

export const updateAssets = createAsyncThunk("userDetails/updateAssets", async (data, { rejectWithValue }) => {
  const assetsList = await reusableFetch(`customer-assets`, "PUT", data, rejectWithValue);
  return assetsList?.data;
});

export const createAssets = createAsyncThunk("userDetails/createAssets", async (data, { rejectWithValue }) => {
  const assetsList = await reusableFetch(`customer-assets`, "POST", data, rejectWithValue);
  return assetsList;
});

export const deleteAssets = createAsyncThunk("userDetails/deleteAssets", async (data, { rejectWithValue }) => {
  const assetsList = await reusableFetch(`customer-assets`, "DELETE", data, rejectWithValue);
  return assetsList;
});

export const getListOfMakes = createAsyncThunk("asset/getListOfMakes", async (data, { rejectWithValue }) => {
  const assetsList = await reusableFetch(
    `asset/getCombinedListOfMakes?ModelTypeCode=${data.assetTypeCode}&YearCreate=${data.year}`,
    "GET",
    // data,
    rejectWithValue,
  );
  return assetsList;
});

export const getListOfMakesYearFromTo = createAsyncThunk(
  "asset/getListOfMakesYearFromTo",
  async (data, { rejectWithValue }) => {
    const assetsList = await reusableFetch(
      `asset/getCombinedListOfMakesYearFromTo?ModelTypeCode=${data.assetTypeCode}&YearFrom=${data.from}&YearTo=${data.to}`,
      "GET",
      // data,
      rejectWithValue,
    );
    return assetsList;
  },
);

export const getListOfModels = createAsyncThunk("asset/getListOfModels", async (data, { rejectWithValue }) => {
  const assetsList = await reusableFetch(
    `asset/getCombinedListOfModels?ModelTypeCode=${data.assetTypeCode}&YearCreate=${data.year}&ManufacturerCode=${data.makeCode}`,
    "GET",
    // data,
    rejectWithValue,
  );
  return assetsList;
});

export const getListOfModelsYearFromTo = createAsyncThunk(
  "asset/getListOfModelsYearFromTo",
  async (data, { rejectWithValue }) => {
    const assetsList = await reusableFetch(
      `asset/getCombinedListOfModelsYearFromTo?ModelTypeCode=${data.assetTypeCode}&YearFrom=${data.from}&YearTo=${data.to}&ManufacturerCode=${data.makeCode}`,
      "GET",
      // data,
      rejectWithValue,
    );
    return assetsList;
  },
);

export const getListOfVariants = createAsyncThunk("asset/getListOfVariants", async (data, { rejectWithValue }) => {
  const assetsList = await reusableFetch(
    // `asset/getListOfVariants?ModelTypeCode=${data.assetTypeCode}&YearCreate=${data.year}&ManufacturerCode=${data.makeCode}&FamilyCode=${data.modelCode}`,
    `asset/getCombinedListOfVariants?ModelTypeCode=A&YearCreate=${data.year}&ManufacturerCode=${data.makeCode}&FamilyCode=${data.modelCode}`,
    "GET",
    // data,
    rejectWithValue,
  );
  return assetsList;
});

export const getListOfVariantsYearFromTo = createAsyncThunk(
  "asset/getListOfVariantsYearFromTo",
  async (data, { rejectWithValue }) => {
    const assetsList = await reusableFetch(
      `asset/getListOfVariantsYearFromTo?ModelTypeCode=${data.assetTypeCode}&YearFrom=${data.from}&YearTo=${data.to}&ManufacturerCode=${data.makeCode}&FamilyCode=${data.modelCode}`,
      "GET",
      // data,
      rejectWithValue,
    );
    return assetsList;
  },
);

export const getListOfSeries = createAsyncThunk("asset/getListOfSeries", async (data, { rejectWithValue }) => {
  const assetsList = await reusableFetch(
    `asset/GetListOfSeries?ModelTypeCode=${data.assetTypeCode}&YearCreate=${data.yearCreate}&ManufacturerCode=${data.makeCode}&FamilyCode=${data.modelCode}&VariantName=${data.variant}`,
    "GET",
    // data,
    rejectWithValue,
  );
  return assetsList;
});

export const getListOfNVICForAdvancedSearch = createAsyncThunk(
  "asset/getListOfNVICForAdvancedSearch",
  async (data, { rejectWithValue }) => {
    const assetsList = await reusableFetch(
      // `asset/GetListOfNVICForAdvancedSearch?ModelTypeCode=APAS&YearCreate=2019&ManufacturerCode=ALF&FamilyCode=GIU&VariantName=QUADRIFOGLIO&SeriesCode=19&Keyword=A`,
      `asset/getListOfNVICForAdvancedSearch?ModelTypeCode=${data.assetTypeCode}&YearCreate=${data.yearCreate}&ManufacturerCode=${data.makeCode}&FamilyCode=${data.modelCode}&VariantName=${data.variant}&Keyword=A`,
      "GET",
      // data,
      rejectWithValue,
    );
    return assetsList;
  },
);

export const getDetailsSpecificationAll = createAsyncThunk(
  "asset/getDetailsSpecificationAll",
  async (data, { rejectWithValue }) => {
    const assetsList = await reusableFetch(
      // `asset/getDetailsSpecificationAll?ModelTypeCode=${data.assetTypeCode}&NVIC=${data.NVIC}`,
      `asset/getDetailsSpecificationAll?ModelTypeCode=A&NVIC=${data.NVIC}`,
      "GET",
      // data,
      rejectWithValue,
    );
    return assetsList;
  },
);

export const getLookupBlueflag = createAsyncThunk("asset/getLookupBlueflag", async (query, { rejectWithValue }) => {
  const assetsList = await reusableFetch(
    `asset/getLookupBlueflag?${query}&include_nvic=true`,
    "GET",
    // data,
    rejectWithValue,
  );
  return assetsList;
});

export const getPPSRSearchBlueflag = createAsyncThunk(
  "asset/getPPSRSearchBlueflag",
  async (query, { rejectWithValue }) => {
    const result = await reusableFetch(`asset/getPPSRSearchBlueflag?${query}`, "GET", rejectWithValue);
    return result;
  },
);

export const updateApplicationAgreement = createAsyncThunk(
  "userDetails/updateApplicationAgreement",
  async (detail, { rejectWithValue }) => {
    const data = {
      introducerDeclarationAgree: detail.introducerDeclarationAgree,
    };
    const assetsList = await reusableFetch(
      `application/introducerDeclaration/${detail._id}`,
      "POST",
      data,
      rejectWithValue,
    );
    return assetsList;
  },
);

export const sendDisclosure = createAsyncThunk("application/disclosure", async (data, { rejectWithValue }) => {
  const application = await reusableFetch(`application/disclosure`, "POST", data, rejectWithValue);
  return application;
});

export const updateNccpDeclaration = createAsyncThunk(
  "application/updateNccpDeclaration",
  async (data, { rejectWithValue }) => {
    const application = await reusableFetch(`customer-disclaimer`, "PUT", data, rejectWithValue);
    return application;
  },
);

export const customerLogin = createAsyncThunk("customer/login", async (data, { rejectWithValue }) => {
  const response = await reusableWithoutTokenFetch(`auth/customer/login`, "POST", data, rejectWithValue);
  return response;
});

export const sendCustomerDetailsLinkToCustomer = createAsyncThunk("customer/sendLink", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`customer/details/link`, "POST", data, rejectWithValue);
  return response;
});

export const saveCustomerDetails = createAsyncThunk("customer/save", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`customer/details/save`, "POST", data, rejectWithValue);
  return response;
});

export const getCloneApplication = createAsyncThunk(
  "application/clone",
  async (applicationId, { rejectWithValue }) => {
    const application = await reusableFetch(
      `application/clone/${applicationId}`,
      "GET",
      rejectWithValue
    );
    return application?.data;
  }
);

export const getConnectiveDocsTemplates = createAsyncThunk(
  "application/documentSelection",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch("application/connective/docs/templates", "GET", {}, rejectWithValue);
    return response;
  },
);

const initialState = {
  singleEntityElement: {},
  gettingQuote: null,
  confirmCustomerDataLoader: false,
  gettingClonedApplication: false,
  startApplicationBtnLoader: false,
  gettingCalculator: false,
  linkAppToOppLoader: false,
  loadingSubmitApplication: false,
  verifiedDialogBoxIsOpen: false,
  customerPrivacyRequest: false,
  idVerificationRequest: false,
  bankStatementsRequest: false,
  linkSent: false,
  sendingIDLink: null,
  ApplicationBtn: false,
  gettingASICData: null,
  gettingASICPPSRData: null,
  gotASICData: null,
  gettingEntityDetails: false,
  gettingCreditProfile: false,
  gettingIllionCreditProfile: false,
  gettingEquifaxCreditFile: false,
  gettingEquifaxCreditCompanyFile: false,
  gettingIllionCreditFile: false,
  canCalculateQuote: false,
  gettingAddressDetails: false,
  isManualAddress: {
    show: false,
    customerId: null
  },
  currentProductLenderId: "",
  directors: [],
  shareHolders: [],
  beneficiaries: [],
  shares: [],
  businessAddresses: [],
  applicants: [],
  entityId: "",
  entity: {},
  identityVerification: [],
  identityDocument: [],
  address: {},
  customer: {},
  document: {},
  templateDocuments: {},
  gettingDocumentSelections: false,
  creditProfile: {},
  illionCreditProfile: {},
  open: false,
  uploadPrivacyLoading: "",
  uploadIDLoading: "",
  documentUploadLoding: "",
  isUpdatedLoanDetails: null,
  gettingStartApplication: false,
  gettingApplication: false,
  downloadApplicationLoader: false,
  disclosureLoader: false,
  activityLog: [],
  applicationServiceLog: [],
  creditScoreTypes: [],
  applicationServiceLogLoader: false,
  openManualQuote: false,
  removeEmployerLoader: true,
  openConnectiveContactModel: false,
  gettingExistingContactListLoader: false,
  connectiveContactList: [],
  personalFinLoaders: {
    id: "",
    addAssetLoader: false,
    removeAssetLoader: false,
    addLiabilityLoader: false,
    removeLiabilityLoader: false,
    addIncomeLoader: false,
    removeIncomeLoader: false,
    addExpenseLoader: false,
    removeExpenseLoader: false,
  },
  requireFieldErrors: {},
  validationRequireFieldError: {},
  lenderRequiredFieldsError: {
    assetsFieldErrors: {
      assetValue: "",
      typeOfSale: "",
      condition: "",
      make: "",
      model: "",
      glassesMake: "",
      glassesModel: "",
      glassesVariant: "",
      glassesNVIC: "",
      glassesData: "",
    },
    loanDetailsFieldErrors: {},
    consumerFieldErrors: {
      customerTitle: "",
      firstName: "",
      lastName: "",
      IDNumber: "",
      dateOfBirth: "",
      IDCardNumber: "",
      phoneNumber: "",
      emailAddress: "",
      address: "",
      yearsAt: "",
      monthsAt: "",
      IDExpiryDate: "",
      customerAssets: {
        assetType: "",
        value: "",
        description: "",
      },
      liabilities: {
        liabilitiesType: "",
        outstandingAmount: "",
        limit: "",
        financier: "",
        repaymentFrequency: "",
        repaymentAmount: "",
        description: "",
      },
      income: {
        incomeType: "",
        amount: "",
        frequency: "",
      },
      expenses: {
        expensesType: "",
        amount: "",
        frequency: "",
      },
    },
    agreementsFieldErrors: {
      introducerDeclarationAgree: "",
    },
    nccpFieldErrors: {
      whatIsThePurposeOfThisApplication: "", // Enum
      whyDoesThisLoanTermSuitYourRequirements: "", // Enum
      whatAreYouLookingForInYourLoan: "", // Enum
      anyForeseeableChangesImpactingLoanRepayment: "", // Enum
      howApplicantIntendToDoLoanRepaymentAfterChange: "", // Textarea
      increaseInLoanAmountForFinancingCostOfInsurance: "", // boolean
      balloonPaymentAtTheEndOfTheLoan: "", // boolean
      whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm: "", // Enum
    },
    entityFieldErrors: {
      address: "",
    },
    guarantorFieldErrors: {
      customerTitle: "",
      firstName: "",
      middleNames: "",
      lastName: "",
      dateOfBirth: "",
      IDNumber: "",
      IDCardNumber: "",
      Number: "",
      Address: "",
      address: "",
      yearsAt: "",
      monthsAt: "",
      IDExpiryDate: "",
    },
  },
  application: {
    asset: {
      nvicLoaderData: false,
      makeLoader: true,
      modelLoader: true,
      variantLoader: true,
      NVICLoader: true,
      searchVehicleLoader: false,
      searchPPSRLoader: false,
      blueflagPPSR: {
        search: [],
        nevdisData: {},
      },
      documents: [],
    },
    loanDetails: {},
  },
  // =============================    Calculator Errors   =================================
  calculatorFieldsErrors: {
    finance: {
      lender: "",
      state: "",
      itcLevel: ""
    },
    costOfGoods: {
      net: "",
      gst: "",
      costOfGoodTotal: "",
      additionalFees: "",
      additionalTaxes: "",
    },
    deposit: {
      cash: "",
      tradeIn: "",
    },
    revenue: {
      financedFees: "",
      financedBrokerage: "",
      unFinancedFees: "",
      unFinancedBrokerage: "",
      accountFee: "",
    },
    brokerage: {
      brokerage: "",
      // brokerageAmount: ""
    },
    amountFinanced: {
      brokerage: "",
    },
    balloon: {
      balloon: "",
      // balloonAmount: ""
    },
    rates: {
      rate: "",
      effectiveRate: "",
      gst: "",
    },
    repaymentSchedule: {
      repayment: "",
      repaymentFrequency: "",
      term: "",
      structure: "",
      amortDate: "",
    },
    singleRepayment: {
      repayments: "",
      gst: "",
      accountFee: "",
    },
    allRepayment: {
      net: "",
      gst: "",
      taxExc: "",
      taxInc: "",
    },
    termsCharges: {
      termCharges: ""
    }
  }
};

export const formdataSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    clearState: () => initialState,
    clearUpFinances: (state) => {
      state.assetListData = initialState.assetListData;
      state.liabilitiesListData = initialState.liabilitiesListData;
      state.incomeListData = initialState.incomeListData;
      state.expensesListData = initialState.expensesListData;
    },
    saveValue: (state, action) => Object.assign({}, state, action.payload),
    getCurrentProductLenderId: (state, { payload }) => {
      state.currentProductLenderId = payload;
    },
    singleEntity: (state, { payload }) => {
      state.singleEntityElement = payload;
    },

    updateLiabilitiesList: (state, { payload }) => {
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === payload[0].customer);
      state.application.customers[findindex].liabilities = payload;
    },
    updateIncomeList: (state, { payload }) => {
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === payload[0].customer);
      state.application.customers[findindex].income = payload;
    },
    updateExpensesList: (state, { payload }) => {
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === payload[0].customer);

      state.application.customers[findindex].expenses = payload;
    },
    updateAssetList: (state, { payload }) => {
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === payload[0].customer);

      state.application.customers[findindex].customerAssets = payload;
    },
    saveAsset: (state, action) => {
      state.application.asset = _.merge(state.application.asset, action.payload);
    },
    saveLoanDetails: (state, action) => {
      state.application.loanDetails = _.merge(state.application.loanDetails, action.payload);
    },
    verifiedDialogBox: (state, { payload }) => {
      state.verifiedDialogBoxIsOpen = payload;
    },
    updateAgeOfAsset: (state, { payload }) => {
      state.application.asset.ageOfAsset = payload;
    },
    updateGlassAsset: (state, { payload }) => {
      state.application.asset = payload;
    },
    saveCustomer: (state, action) => {
      // console.log("action.payload", action.payload);
      _.merge(state.application.customers[0], action.payload);
    },
    setRequiredFieldsError: (state, { payload }) => {
      // console.log("========setRequiredFieldsError==========");
      // console.log("Payload:", payload?.consumerFieldErrors?.liabilities);
      state.lenderRequiredFieldsError = payload;
    },
    setRequiredFieldsErrors: (state, { payload }) => {
      // console.log("payload",payload);
      state.requireFieldErrors = payload;
    },
    setValidationRequireFieldError: (state, { payload }) => {
      // console.log("payload",payload);
      state.validationRequireFieldError = payload;
    },
    setCalculatorFieldsError: (state, { payload }) => {
      state.calculatorFieldsErrors = payload;
    },
    setNewCommercialCreditFile: (state, { payload }) => {
      const foundEntity = state.application.entities.find((entity) => entity?._id === payload?.entity);
      if (foundEntity) {
        foundEntity.documents.push(payload);
      }
    },
    setNewCustomerCreditFile: (state, { payload }) => {
      const foundCustomer = state.application.customers.find((customer) => customer?._id === payload?.customer);
      if (foundCustomer) {
        foundCustomer.documents.push(payload);
      }
    },
    setNewIllionCreditFile: (state, { payload }) => {
      const foundCustomer = state.application.customers.find((customer) => customer?._id === payload?.customer);
      if (foundCustomer) {
        foundCustomer.documents.push(payload);
      }
    },
    setNewCustomerBankStatementFile: (state, { payload }) => {
      const foundCustomer = state.application.customers.find((customer) => customer?._id === payload?.customer);
      if (foundCustomer) {
        foundCustomer.documents.push(payload);
      }
    },
    setNewDocumentFile: (state, { payload }) => {
      const foundCustomer = state.application.customers.find((customer) => customer?._id === payload?.customer);
      if (foundCustomer) {
        foundCustomer.documents.push(payload);
      }
    },
    setNewAssetPPSRFile: (state, { payload }) => {
      state.application.asset.documents.push(payload);
    },
    setNewActivityLog: (state, { payload }) => {
      state.activityLog.push(payload);
    },
    setNewServiceUsageLog: (state, { payload }) => {
      state.applicationServiceLog.unshift(payload);
    },
    updateCustomerData: (state, { payload }) => {
      const customerId = payload?.customerId || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === customerId);
      state.application.customers[findindex] = _.merge(state.application.customers[findindex], payload.data)
      // if(payload?.isMultipleUpdate){
      //   state.application.customers[findindex] = _.merge(state.application.customers[findindex], payload.data)
      // }else{
      //   state.application.customers[findindex][payload?.key] = payload?.value;
      // }
    }
  },
  extraReducers: {
    // [updateCustomer.fulfilled]: (state, { payload }) => {
    //   const updatedUser = payload?.data?.data || [];
    //   const data = current(state.application.customers);
    //   let findindex = data.findIndex((el) => el?._id === updatedUser?._id);
    //   state.application.customers[findindex] = updatedUser;
    // },
    [confirmCustomerData.fulfilled]: (state, { payload }) => {
      const updatedUser = payload?.data?.data || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === updatedUser?._id);
      state.application.customers[findindex] = updatedUser;
      state.confirmCustomerDataLoader = false;
    },
    [confirmCustomerData.pending]: (state) => {
      state.confirmCustomerDataLoader = true;
     },
    [confirmCustomerData.rejected]: (state, { payload }) => {
      state.confirmCustomerDataLoader = false;
    },
    [startApplication.fulfilled]: (state, { payload }) => {
      state.isNewApplication = true;
      // state.successMessage = payload.message;
      state.application = _.merge(state.application, payload.data.data.application);
      state.gettingStartApplication = false;
    },
    [startApplication.pending]: (state) => {
      state.application = initialState.application
      state.gettingStartApplication = true;
     },
    [startApplication.rejected]: (state, { payload }) => {
      // state.errorMessage = payload.message;
      state.gettingStartApplication = false;
    },
    [linkToOpportunity.fulfilled]: (state, { payload }) => {
      state.linkAppToOppLoader = false;
      state.application = _.merge(state.application, payload.data.data);
    },
    [linkToOpportunity.pending]: (state) => {
      state.linkAppToOppLoader = true;
    },
    [linkToOpportunity.rejected]: (state, { payload }) => {
      state.linkAppToOppLoader = false;
    },
    [getApplication.fulfilled]: (state, { payload }) => {
      state.application = _.merge(state.application, payload?.data?.data);
      state.gettingApplication = false;
      state.gettingQuote = false;
      state.gettingCalculator = false;
    },
    [getApplication.pending]: (state) => {
      state.gettingApplication = true;
      state.application = initialState.application;
    },
    [getApplication.rejected]: (state, { payload }) => {
      state.gettingApplication = false;
      // state.errorMessage = payload.message;
    },
    [getApplicationComments.fulfilled]: (state, { payload }) => {
      state.applicationComments = payload?.data?.data;
      state.gettingApplicationComment = false;
    },
    [getApplicationComments.pending]: (state) => {
      state.gettingApplicationComment = true;
    },
    [getApplicationComments.rejected]: (state, { payload }) => {
      state.gettingApplicationComment = false;
    },

    [getApplicationActivityLog.fulfilled]: (state, { payload }) => {
      state.activityLog = payload?.data?.data;
      state.gettingactivityLog = false;
    },
    [getApplicationActivityLog.pending]: (state) => {
      state.gettingactivityLog = true;
    },
    [getApplicationActivityLog.rejected]: (state, { payload }) => {
      state.gettingactivityLog = false;
      // state.errorMessage = payload.message;
    },

    [getApplicationServiceLog.fulfilled]: (state, { payload }) => {
      state.applicationServiceLog = payload?.data;
      state.applicationServiceLogLoader = false;
    },
    [getApplicationServiceLog.pending]: (state) => {
      state.applicationServiceLogLoader = true;
    },
    [getApplicationServiceLog.rejected]: (state, { payload }) => {
      state.applicationServiceLog = [];
      state.applicationServiceLogLoader = false;
    },

    [updateApplication.fulfilled]: (state, { payload }) => {
      state.application = _.merge(state.application, payload.data.data);
      state.gettingApplication = false;
    },
    [updateApplication.pending]: (state) => { },
    [updateApplication.rejected]: (state, { payload }) => {
      state.gettingApplication = false;
      // state.errorMessage = payload.message;
    },
    [updateAppStatusToWorkShop.pending]: (state) => {
      state.startApplicationBtnLoader = true;
    },
    [updateAppStatusToWorkShop.rejected]: (state) => {
      state.startApplicationBtnLoader = false;
    },
    [updateAppStatusToWorkShop.fulfilled]: (state) => {
      state.startApplicationBtnLoader = false;
    },
    [submitApplication.pending]: (state) => {
      state.ApplicationBtn = true;
    },
    [submitApplication.rejected]: (state, { payload }) => {
      state.ApplicationBtn = false;
      // state.errorMessage = payload.message;
    },
    [submitApplication.fulfilled]: (state, { payload }) => {
      // state.successMessage = payload.message;
      if (!payload) {
        state.applyApplication = false;
      }
      state.ApplicationBtn = false;
      state.loadingSubmitApplication = false;
    },

    [getApplicationDownloadUrl.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.downloadApplicationLoader = false;
      state.url = payload.data.data;
    },
    [getApplicationDownloadUrl.pending]: (state) => {
      state.downloadApplicationLoader = true;
    },
    [getApplicationDownloadUrl.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.downloadApplicationLoader = false;
      state.errorMessage = payload.message;
    },

    [uploadInvoice.fulfilled]: (state, { payload }) => {
      _.merge(state.application.asset, payload.data);
    },

    [updateAsset.fulfilled]: (state, { payload }) => {
      // state.isFetching = false;
      // state.isSuccess = true;
      // state.successMessage = payload.message;
      state.application.asset = _.merge(state.application.asset, payload.data);
    },
    [updateAsset.pending]: (state) => { },
    [updateAsset.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [updateLoanDetails.fulfilled]: (state, { payload }) => {
      // state.isFetching = false;
      // state.isSuccess = true;
      // state.successMessage = payload.message;
      // state.application.loanDetails = payload.data.data;
      // state.isUpdatedLoanDetails = true;
      state.application.loanDetails = _.merge(state.application.loanDetails, payload.data.data);
    },

    [updateLoanDetails.pending]: (state) => { },
    [updateLoanDetails.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [createCustomer.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application = payload.data.data;
    },
    [createCustomer.pending]: (state) => { },
    [createCustomer.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [getCustomer.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.customer[payload.data._id] = payload?.data.data;
    },
    [getCustomer.pending]: (state) => {
      // state.isFetching = true;
    },

    [removeCustomer.fulfilled]: (state, { payload }) => {
      state.application = payload?.data.data;
    },
    [removeCustomer.pending]: (state) => { },
    [removeCustomer.rejected]: (state, { payload }) => {
      state.isFetching = false;
    },

    [getAddress.fulfilled]: (state, { payload }) => {
      state.successMessage = payload?.message;
      return Object.assign({}, state.customer[payload?.data.data._id], payload.data);
    },
    [getAddress.pending]: (state) => { },
    [getAddress.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },
    [addAddress.pending]: (state) => {
      state.gettingAddressDetails = true
    },
    [addAddress.rejected]: (state, { payload }) => {
      state.gettingAddressDetails = false
    },
    [addAddress.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.gettingAddressDetails = false
      state.successMessage = payload.message;
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === payload?.data.data._id);

      state.application.customers[findindex] = payload.data.data;
      // state.application.customers.filter(
      //   (customer) => customer._id === payload.data._id
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === payload.data._id
      //   )[0],
      //   payload.data
      // );
    },

    [updateAddress.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      const updatedUser = payload?.data || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === updatedUser?._id);
      state.application.customers[findindex] = updatedUser;
      // state.application.customers.filter(
      //   (customer) => customer._id === payload?.data?.customer
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === payload?.data?.customer
      //   )[0]?.addresses,
      //   payload.data
      // );
    },
    [deleteAddress.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === payload?.data?._id);
      state.application.customers[findindex].addresses = payload?.data?.addresses;
      // state.application.customers.filter(
      //   (customer) => customer._id === payload.data._id
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === payload.data._id
      //   )[0],
      //   payload.data
      // );
    },

    [addPhoneNumber.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      if (payload?.data?.data?.abn) {
        state.application.entities.forEach((entity) => {
          if (entity._id === payload?.data?.data?._id) {
            entity.phoneNumbers = payload?.data?.data?.phoneNumbers;
          }
        });
      } else {
        state.application.customers.forEach((customer) => {
          if (customer._id === payload?.data?.data?._id) {
            customer.phoneNumbers = payload?.data?.data?.phoneNumbers;
          }
        });
      }
    },

    [updateContactFromEmail.fulfilled]: (state, { payload }) => {
      const updatedUser = payload?.data?.data || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === updatedUser?._id);
      state.application.customers[findindex] = updatedUser;
    },
    [addConnectiveEmail.pending]: (state, { payload }) => {
      state.openConnectiveContactModel = true
      state.gettingExistingContactListLoader = true
    },
    [addConnectiveEmail.fulfilled]: (state, { payload }) => {
      state.connectiveContactList = payload.data?.data
      state.gettingExistingContactListLoader = false
      // Now it base open and close base on conditions
      // state.openConnectiveContactModel = payload.data?.data?.length > 0 ? true : false
    },
    [addConnectiveEmail.rejected]: (state, { payload }) => {
      state.openConnectiveContactModel = false
      state.gettingExistingContactListLoader = false
    },
    [addEmail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;

      if (payload?.data?.data?.abn) {
        state.application.entities.forEach((entity) => {
          if (entity._id === payload?.data?.data?._id) {
            entity.emails = payload?.data?.data?.emails;
          }
        });
      } else {
        state.application.customers.forEach((customer) => {
          if (customer._id === payload?.data?.data?._id) {
            customer.emails = payload?.data?.data?.emails;
          }
        });
      }
    },

    [saveCustomerProfile.fulfilled]: (state, { payload }) => {
      state.application.customers.filter(
        (customer) => customer._id === payload?.data.data.customer,
      )[0].customerProfile = _.merge(
        state.application.customers.filter((customer) => customer._id === payload?.data.data.customer)[0]
          .customerProfile,
        payload.data.data,
      );
    },
    [saveCustomerProfile.pending]: (state) => { },
    [saveCustomerProfile.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [createEntity.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.entity = _.merge(state.application.entities, payload.data.data.entities);
      state.application.customers = _.merge([], state.application.customers, payload.data.data.customers);
      state.gettingEntityDetails = false;
    },
    [createEntity.pending]: (state) => { },
    [createEntity.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [removeEntity.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.entities = payload.data.data;
      state.gettingEntityDetails = false;
    },
    [removeEntity.pending]: (state) => { },
    [removeEntity.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [createEmployer.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.customers.filter((customer) => customer._id === payload?.data?.data[0].customer)[0].employers =
        _.merge(
          state.application.customers.filter((customer) => customer._id === payload?.data?.data[0].customer)[0]
            .employers,
          payload.data.data,
        );

      state.gettingEntityDetails = false;
    },
    [createEmployer.pending]: (state) => {
      state.gettingEntityDetails = true;
    },
    [createEmployer.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
      state.gettingEntityDetails = false;
    },

    [saveEmployer.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.customers.filter((customer) => customer._id === payload?.data.data[0].customer)[0].employers =
        _.merge(
          state.application.customers.filter((customer) => customer._id === payload?.data.data[0].customer)[0]
            .employers,
          payload.data.data,
        );

      state.gettingEntityDetails = false;
    },
    [saveEmployer.pending]: (state) => { },
    [saveEmployer.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [getASICData.fulfilled]: (state, { payload }) => {
      // state.successMessage = payload.message;
      state.gettingASICData = false;
      // state.gettingASICPPSRData = false;
      state.application.entity = _.merge(state.application.entities, payload.data.entities);
      state.application.customers = _.merge([], state.application.customers, payload.data.customers);

      // state.gotASICData = true;
    },
    [getASICData.pending]: (state) => {
      // state.gotASICData = false;
    },
    [getASICData.rejected]: (state, { payload }) => {
      state.gettingASICData = false;
      // state.gettingASICPPSRData = false;
      state.errorMessage = payload.message;
    },

    [updateEntity.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      // _.merge({}, state.application, payload?.data);

      // state.application.entities.filter(
      //   (entity) => entity._id === payload?.data?._id
      // )[0] = _.merge(
      //   state.application.entities.filter(
      //     (entity) => entity._id === payload?.data?._id
      //   )[0],
      //   payload?.data
      // );
      const data = current(state.application.entities);
      let findindex = data.findIndex((el) => el?._id === payload?.data?._id);
      // console.log("create====", current(state.application.customers[findindex]?.customerAssets));
      state.application.entities[findindex] = payload?.data;
    },
    [updateEntity.pending]: (state) => { },
    [updateEntity.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [getEntity.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.entity = Object.assign({}, state.entity, payload.data.data);
    },
    [getEntity.pending]: (state) => { },
    [getEntity.rejected]: (state) => { },

    [startVerification.pending]: (state) => { },
    [startVerification.fulfilled]: (state, { payload }) => {
      state.idVerificationRequest = true;
    },
    [startVerification.rejected]: (state) => { },

    [getIdentityVerification.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.customers.filter((customer) => customer._id === payload?.data?._id)[0] = _.merge(
        state.application.customers.filter((customer) => customer._id === payload?.data?._id)[0],
        payload?.data,
      );
      JSON.stringify(state.application.customers.filter((customer) => customer._id === payload?.data?._id)[0], null, 2);
    },
    [getIdentityVerification.pending]: (state) => { },
    [getIdentityVerification.rejected]: (state) => { },

    [getDocuments.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.customers.filter((customer) => customer._id === payload.data.data._id)[0] = _.merge(
        state.application.customers.filter((customer) => customer._id === payload.data.data._id)[0],
        payload.data.data,
      );
    },
    [getCreditFile.pending]: (state) => {
      state.gettingCreditProfile = true;
      state.gettingEquifaxCreditFile = true;
    },
    [getCreditFile.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.gettingCreditProfile = false;
      state.application.customers.filter((customer) => customer._id === payload?.data?.data._id)[0] = _.merge(
        state.application.customers.filter((customer) => customer._id === payload?.data?.data._id)[0],
        payload.data.data,
      );
    },
    [getCreditFile.rejected]: (state) => {
      state.gettingCreditProfile = false;
    },
    [getIllionCreditReportFile.pending]: (state) => {
      state.gettingIllionCreditProfile = true;
      state.gettingIllionCreditFile = true;
    },
    [getIllionCreditReportFile.fulfilled]: (state, {payload}) => {
      state.gettingIllionCreditFile = false;
      state.gettingIllionCreditProfile = false;
      state.successMessage = payload.message;

      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === payload?.data?.data._id);
      state.application.customers[findindex] = payload?.data?.data;

      // state.application.customers.filter((customer) => customer._id === payload?.data?.data._id)[0] = _.merge(
      //   state.application.customers.filter((customer) => customer._id === payload?.data?.data._id)[0],
      //   payload.data.data,
      // );

    },
    [getIllionCreditReportFile.rejected]: (state) => {
      state.gettingIllionCreditProfile = false;
      state.gettingIllionCreditFile = false;
    },
    [getCommercialCreditFile.pending]: (state) => {
      state.gettingEquifaxCreditCompanyFile = true;
    },
    [getCommercialCreditFile.fulfilled]: (state, { payload }) => {
      state.successMessage = payload?.data?.status_message;
      state.gettingCreditProfile = false;

      const updatedEntities = payload?.data?.data;
      updatedEntities.forEach((updatedEntity) => {
        const existingEntity = state.application.entities.find((entity) => entity._id === updatedEntity._id);
        if (existingEntity) {
          // Merge the new documents with the existing documents from socket
          updatedEntity.documents = [
            ...existingEntity.documents,
            ...updatedEntity.documents.filter(
              (document) => !existingEntity.documents.some((doc) => doc._id === document._id),
            ),
          ];
        } else {
          state.application.entities.push(updatedEntity);
        }
      });
      state.application.entities = updatedEntities;
    },
    [getCommercialCreditFile.rejected]: (state) => {
      state.gettingCreditProfile = false;
    },

    [getCreditProfile.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      if (payload.data?._id) state.creditProfile[payload.data?.customer] = payload?.data;
    },
    [getIllionCreditProfile.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      if (payload.data?._id) state.ilionCreditProfile[payload.data?.customer] = payload?.data;
    },
    [sendPrivacyLink.pending]: (state) => {
      state.privacyLoading = true;
    },
    [sendPrivacyLink.fulfilled]: (state, { payload }) => {
      state.customerPrivacyRequest = true;
      state.successMessage = payload.message;
      state.privacyLoading = false;
      state.document[payload.data.data.documentId] = payload.data.data.url;
    },

    [sendBankStatementsLink.pending]: (state) => {
      state.bankStatementsLoading = true;
    },
    [sendBankStatementsLink.fulfilled]: (state, { payload }) => {
      state.document[payload.data.data.documentId] = payload.data.data.url;
      state.bankStatementsLoading = false;
      state.bankStatementsRequest = true;
    },
    [sendBankStatementsLink.rejected]: (state) => {
      state.bankStatementsLoading = false;
    },

    [getFileUrl.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      _.merge(state.document[payload.data.data.documentId], payload.data.data.url);
    },
    [updateNotes.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.notes = payload?.data?.data;
    },

    [uploadDocument.fulfilled]: (state, { payload }) => {
      state.application.customers.filter((customer) => customer._id === payload.data.data._id)[0] = _.merge(
        state.application.customers.filter((customer) => customer._id === payload.data.data._id)[0],
        payload.data.data,
      );
      if (state.uploadPrivacyLoading === true) state.uploadPrivacyLoading = false;

      if (state.documentUploadLoading === true) {
        state.documentUploadLoading = false;
      }
      if (state.uploadIDLoading === true) state.uploadIDLoading = false;
    },
    [uploadDocument.pending]: (state) => {
      state.documentUploadLoding = true;
    },
    [uploadDocument.rejected]: (state) => {
      state.documentUploadLoding = false;
      state.uploadPrivacyLoading = false;
      state.uploadIDLoading = false;
    },
    [createFinanceItem.fulfilled]: (state, { payload }) => {
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) => customer._id === payload.data.data[Object.keys(payload.data.data)[0]].customer,
      )[0][Object.keys(payload.data.data)[0]] = payload.data.data[Object.keys(payload.data.data)[0]];

      JSON.stringify(
        state.application.customers.filter(
          (customer) => customer._id === payload.data.data[Object.keys(payload.data.data)[0]].customer,
        )[0].null,
        2,
      );
    },
    [deleteFinanceItem.fulfilled]: (state, { payload }) => {
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) => customer._id === payload.data.data[Object.keys(payload.data.data)[0]].customer,
      )[0][Object.keys(payload.data.data)[0]] = payload.data.data[Object.keys(payload.data.data)[0]];

      JSON.stringify(
        state.application.customers.filter(
          (customer) => customer._id === payload.data.data[Object.keys(payload.data.data)[0]].customer,
        )[0].null,
        2,
      );
    },
    [updateCreditProfile.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      state.application.customers.filter((customer) => customer._id === payload.data.data.customer)[0] = _.merge(
        state.application.customers.filter((customer) => customer._id === payload.data.data.customer)[0].creditProfile,
        payload.data.data,
      );
    },
    [createAssets.pending]: (state) => {
      state.personalFinLoaders.addAssetLoader = true;
    },
    [createAssets.fulfilled]: (state, { payload }) => {
      const assetsList = payload?.data?.data || [];

      // state.application.customers.filter(
      //   (customer) => customer._id === assetsList[0].customer
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === assetsList[0].customer
      //   )[0].customerAssets,
      //   assetsList
      // );
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === assetsList[0]?.customer);
      // console.log("create====", current(state.application.customers[findindex]?.customerAssets));
      state.application.customers[findindex].customerAssets = assetsList;
      state.personalFinLoaders = {
        id: "",
        addAssetLoader: false,
      };
    },
    [createAssets.rejected]: (state) => {
      state.personalFinLoaders = {
        id: "",
        addAssetLoader: false,
      };
    },
    [updateAssets.pending]: (state) => { },
    [updateAssets.fulfilled]: (state, { payload }) => {
      const assetsList = payload?.data || [];
      const data = current(state.application.customers);
      let findindex = data?.findIndex((el) => el?._id === assetsList?.[0]?.customer);
      state.application.customers[findindex].customerAssets = assetsList;
    },
    [updateAssets.rejected]: (state) => { },
    [deleteAssets.pending]: (state) => {
      state.personalFinLoaders.removeAssetLoader = true;
    },
    [deleteAssets.fulfilled]: (state, { payload }) => {
      const assetsList = payload?.data?.data || [];
      // console.log("assetsList", assetsList);

      // state.application.customers.filter(
      //   (customer) => customer._id === assetsList[0].customer
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === assetsList[0].customer
      //   )[0]?.customerAssets,
      //   assetsList
      // );
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === assetsList[0]?.customer);
      state.application.customers[findindex].customerAssets = assetsList;
      state.personalFinLoaders = {
        id: "",
        removeAssetLoader: false,
      };
    },
    [deleteAssets.rejected]: (state) => {
      state.personalFinLoaders = {
        id: "",
        removeAssetLoader: false,
      };
    },
    [createExpense.pending]: (state) => {
      state.personalFinLoaders.addExpenseLoader = true;
    },
    [createExpense.fulfilled]: (state, { payload }) => {
      const expensesList = payload?.data?.data || [];
      // state.application.customers.filter(
      //   (customer) => customer._id === expensesList[0].customer
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === expensesList[0].customer
      //   )[0].expenses,
      //   expensesList
      // );
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === expensesList[0]?.customer);
      state.application.customers[findindex].expenses = expensesList;
      state.personalFinLoaders = {
        id: "",
        addExpenseLoader: false,
      };
    },
    [createExpense.rejected]: (state) => {
      state.personalFinLoaders = {
        id: "",
        addExpenseLoader: false,
      };
    },
    [updateExpense.pending]: (state) => { },
    [updateExpense.fulfilled]: (state, { payload }) => {
      const expensesList = payload?.data?.data || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === expensesList[0]?.customer);
      state.application.customers[findindex].expenses = expensesList;
    },
    [updateExpense.rejected]: (state) => { },
    [deleteExpense.pending]: (state) => {
      state.personalFinLoaders.removeExpenseLoader = true;
    },
    [deleteExpense.fulfilled]: (state, { payload }) => {
      const expensesList = payload?.data?.data || [];
      // state.application.customers.filter(
      //   (customer) => customer._id === expensesList[0].customer
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === expensesList[0].customer
      //   )[0].expenses,
      //   expensesList
      // );
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === expensesList[0]?.customer);
      state.application.customers[findindex].expenses = expensesList;
      state.personalFinLoaders = {
        id: "",
        removeExpenseLoader: false,
      };
    },
    [deleteExpense.rejected]: (state) => {
      state.personalFinLoaders = {
        id: "",
        removeExpenseLoader: false,
      };
    },
    [deleteEmployer.pending]: (state) => {
      state.removeEmployerLoader = true;
    },
    [deleteEmployer.fulfilled]: (state, { payload }) => {
      state.removeEmployerLoader = false;
      // const employersList = payload?.data?.data || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === payload?.data?.data?._id);
      state.application.customers[findindex].employers = payload?.data?.data?.employers;
    },
    [deleteEmployer.rejected]: (state) => {
      state.removeEmployerLoader = false;
    },
    [createIncome.pending]: (state) => {
      state.personalFinLoaders.addIncomeLoader = true;
    },
    [createIncome.fulfilled]: (state, { payload }) => {
      const incomeList = payload?.data?.data || [];
      // state.application.customers.filter(
      //   (customer) => customer._id === incomeList[0].customer
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === incomeList[0].customer
      //   )[0].income,
      //   incomeList
      // );
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === incomeList[0]?.customer);
      state.application.customers[findindex].income = incomeList;
      state.personalFinLoaders = {
        id: "",
        addIncomeLoader: false,
      };
    },
    [createIncome.rejected]: (state) => {
      state.personalFinLoaders = {
        id: "",
        addIncomeLoader: false,
      };
    },
    [updateIncome.pending]: (state) => { },
    [updateIncome.fulfilled]: (state, { payload }) => {
      // const incomeList = payload?.data?.data || [];
      // const data = current(state.application.customers);
      // let findindex = data.findIndex((el) => el?._id === incomeList[0]?.customer);
      // state.application.customers[findindex].income = incomeList;
      const updatedUser = payload?.data?.data || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === updatedUser?._id);
      state.application.customers[findindex] = updatedUser;
    },
    [updateIncome.rejected]: (state) => { },
    [deleteIncome.pending]: (state) => {
      state.personalFinLoaders.removeIncomeLoader = true;
    },
    [deleteIncome.fulfilled]: (state, { payload }) => {
      const incomeList = payload?.data?.data || [];
      // state.application.customers.filter(
      //   (customer) => customer._id === incomeList[0].customer
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === incomeList[0].customer
      //   )[0].income,
      //   incomeList
      // );
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === incomeList[0]?.customer);
      state.application.customers[findindex].income = incomeList;
      state.personalFinLoaders = {
        id: "",
        removeIncomeLoader: false,
      };
    },
    [deleteIncome.rejected]: (state) => {
      state.personalFinLoaders = {
        id: "",
        removeIncomeLoader: false,
      };
    },
    [createLiabilities.pending]: (state, action) => {
      // console.log("pending", action);
      state.personalFinLoaders.addLiabilityLoader = true;
    },
    [createLiabilities.rejected]: (state, action) => {
      // console.log("rejected", action);
      state.personalFinLoaders = {
        id: "",
        addLiabilityLoader: false,
      };
    },
    [createLiabilities.fulfilled]: (state, { payload }) => {
      const liabilityList = payload?.data?.data || [];

      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === liabilityList[0]?.customer);

      // state.application.customers.filter(
      //   (customer) => customer._id === liabilityList[0].customer
      // )[0] = _.merge(
      //   state.application.customers.filter(
      //     (customer) => customer._id === liabilityList[0].customer
      //   )[0].liabilities,
      //   liabilityList
      // );

      state.application.customers[findindex].liabilities = liabilityList;
      state.personalFinLoaders = {
        id: "",
        addLiabilityLoader: false,
      };
    },
    [updateLiabilities.pending]: (state) => { },
    [updateLiabilities.fulfilled]: (state, { payload }) => {
      const liabilityList = payload?.data?.data || [];

      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === liabilityList[0]?.customer);
      state.application.customers[findindex].liabilities = liabilityList;
    },
    [updateLiabilities.rejected]: (state) => { },
    [deleteLiabilities.pending]: (state) => {
      state.personalFinLoaders.removeLiabilityLoader = true;
    },
    [deleteLiabilities.fulfilled]: (state, { payload }) => {
      const liabilityList = payload?.data?.data || [];

      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === liabilityList[0]?.customer);
      state.application.customers[findindex].liabilities = liabilityList;
      state.personalFinLoaders = {
        id: "",
        removeLiabilityLoader: false,
      };
    },
    [deleteLiabilities.rejected]: (state) => {
      state.personalFinLoaders = {
        id: "",
        removeLiabilityLoader: false,
      };
    },
    [getListOfMakes.pending]: (state) => {
      state.application.asset.makeLoader = true;
      state.application.asset.listOfMakes = [];
    },

    [getListOfMakes.rejected]: (state) => {
      state.application.asset.makeLoader = false;
      state.application.asset.listOfMakes = [];
    },

    [getListOfMakes.fulfilled]: (state, { payload }) => {
      if (JSON.stringify(payload?.data?.data) === "{}") {
        state.application.asset.makeLoader = false;
        state.application.asset.listOfMakes = [];
        return;
      }
      if (!Array.isArray(payload.data.data)) {
        state.application.asset.makeLoader = false;
        state.application.asset.listOfMakes = [payload?.data.data];
      } else {
        state.application.asset.makeLoader = false;
        state.application.asset.listOfMakes = payload?.data.data;
      }
    },

    [getListOfMakesYearFromTo.pending]: (state) => {
      state.application.asset.makeLoader = true;
      state.application.asset.listOfMakes = [];
    },

    [getListOfMakesYearFromTo.rejected]: (state) => {
      state.application.asset.makeLoader = false;
      state.application.asset.listOfMakes = [];
    },

    [getListOfMakesYearFromTo.fulfilled]: (state, { payload }) => {
      if (JSON.stringify(payload.data.data) === "{}") {
        state.application.asset.makeLoader = false;
        state.application.asset.listOfMakes = [];
        return;
      }
      if (!Array.isArray(payload.data.data)) {
        state.application.asset.makeLoader = false;
        state.application.asset.listOfMakes = [payload?.data.data];
      } else {
        state.application.asset.makeLoader = false;
        state.application.asset.listOfMakes = payload?.data.data;
      }
    },
    [getListOfModels.pending]: (state) => {
      state.application.asset.modelLoader = true;
      state.application.asset.listOfModels = [];
    },

    [getListOfModels.rejected]: (state) => {
      state.application.asset.modelLoader = false;
      state.application.asset.listOfModels = [];
    },
    [getListOfModels.fulfilled]: (state, { payload }) => {
      if (JSON.stringify(payload.data.data) === "{}") {
        state.application.asset.modelLoader = false;
        state.application.asset.listOfModels = [];
        return;
      }
      if (!Array.isArray(payload.data.data)) {
        state.application.asset.modelLoader = false;
        state.application.asset.listOfModels = [payload?.data.data];
      } else {
        state.application.asset.modelLoader = false;
        state.application.asset.listOfModels = payload?.data.data;
      }
    },

    [getListOfModelsYearFromTo.pending]: (state) => {
      state.application.asset.modelLoader = true;
      state.application.asset.listOfModels = [];
    },

    [getListOfModelsYearFromTo.rejected]: (state) => {
      state.application.asset.modelLoader = false;
      state.application.asset.listOfModels = [];
    },
    [getListOfModelsYearFromTo.fulfilled]: (state, { payload }) => {
      if (JSON.stringify(payload.data.data) === "{}") {
        state.application.asset.modelLoader = false;
        state.application.asset.listOfModels = [];
        return;
      }
      if (!Array.isArray(payload.data.data)) {
        state.application.asset.modelLoader = false;
        state.application.asset.listOfModels = [payload?.data.data];
      } else {
        state.application.asset.modelLoader = false;
        state.application.asset.listOfModels = payload?.data.data;
      }
    },
    [getListOfVariants.pending]: (state) => {
      state.application.asset.variantLoader = true;
      state.application.asset.listOfVariants = [];
    },

    [getListOfVariants.rejected]: (state) => {
      state.application.asset.variantLoader = false;
      state.application.asset.listOfVariants = [];
    },
    [getListOfVariants.fulfilled]: (state, { payload }) => {
      if (JSON.stringify(payload.data.data) === "{}") {
        state.application.asset.variantLoader = false;
        state.application.asset.listOfVariants = [];
        return;
      }
      if (!Array.isArray(payload.data.data)) {
        state.application.asset.variantLoader = false;
        state.application.asset.listOfVariants = [payload?.data.data];
      } else {
        state.application.asset.variantLoader = false;
        state.application.asset.listOfVariants = payload?.data.data;
      }
    },

    [getListOfVariantsYearFromTo.pending]: (state) => {
      state.application.asset.variantLoader = true;
      state.application.asset.listOfVariants = [];
    },

    [getListOfVariantsYearFromTo.rejected]: (state) => {
      state.application.asset.variantLoader = false;
      state.application.asset.listOfVariants = [];
    },
    [getListOfVariantsYearFromTo.fulfilled]: (state, { payload }) => {
      if (JSON.stringify(payload.data.data) === "{}") {
        state.application.asset.variantLoader = false;
        state.application.asset.listOfVariants = [];
        return;
      }
      if (!Array.isArray(payload.data.data)) {
        state.application.asset.variantLoader = false;
        state.application.asset.listOfVariants = [payload?.data.data];
      } else {
        state.application.asset.variantLoader = false;
        state.application.asset.listOfVariants = payload?.data.data;
      }
    },
    // [getListOfSeries.fulfilled]: (state, { payload }) => {
    //   if (!Array.isArray(payload.data)) {
    //     state.application.asset.listOfSeries = [payload?.data];
    //   } else {
    //     state.application.asset.listOfSeries = payload?.data;
    //   }
    // },

    [getListOfNVICForAdvancedSearch.pending]: (state) => {
      state.application.asset.NVICLoader = true;
      state.application.asset.NVICList = [];
    },

    [getListOfNVICForAdvancedSearch.rejected]: (state) => {
      state.application.asset.NVICLoader = false;
      state.application.asset.NVICList = [];
    },
    [getListOfNVICForAdvancedSearch.fulfilled]: (state, { payload }) => {
      if (JSON.stringify(payload.data.data) === "{}") {
        state.application.asset.NVICLoader = false;
        state.application.asset.NVICList = [];
        return;
      }
      if (!Array.isArray(payload.data.data)) {
        state.application.asset.NVICLoader = false;
        state.application.asset.NVICList = [payload?.data.data];
      } else {
        state.application.asset.NVICLoader = false;
        state.application.asset.NVICList = payload?.data.data;
      }
    },
    [getDetailsSpecificationAll.pending]: (state) => {
      state.application.asset.nvicLoaderData = true;
      state.application.asset.NVICData = null;
    },
    [getDetailsSpecificationAll.rejected]: (state) => {
      state.application.asset.nvicLoaderData = false;
      state.application.asset.NVICData = null;
    },
    [getDetailsSpecificationAll.fulfilled]: (state, { payload }) => {
      state.application.asset.nvicLoaderData = false;
      state.application.asset.NVICData = payload?.data.data;
    },
    [getLookupBlueflag.pending]: (state, { payload }) => {
      state.application.asset.searchVehicleLoader = true;
      state.application.asset.blueFlag = null;
    },
    [getLookupBlueflag.fulfilled]: (state, { payload }) => {
      state.application.asset.searchVehicleLoader = false;
      if (payload?.data?.data?.result) {
        state.application.asset.blueFlag = payload?.data?.data?.result[0];
      }
    },
    [getLookupBlueflag.rejected]: (state, { payload }) => {
      state.application.asset.searchVehicleLoader = false;
      state.application.asset.blueFlag = null;
    },
    [getPPSRSearchBlueflag.pending]: (state, { payload }) => {
      state.application.asset.searchPPSRLoader = true;
    },
    [getPPSRSearchBlueflag.fulfilled]: (state, { payload }) => {
      state.application.asset.searchPPSRLoader = false;
      if (payload?.data?.data?.result) {
        state.application.asset.blueflagPPSR.search = payload?.data?.data?.result?.[0]?.search_result.result_details;
        state.application.asset.blueflagPPSR.nevdisData = payload?.data?.data?.result?.[0]?.search_result?.nevdis_data;
      }
    },
    [getPPSRSearchBlueflag.rejected]: (state, { payload }) => {
      state.application.asset.searchPPSRLoader = false;
    },
    [updateApplicationAgreement.pending]: (state) => { },
    [updateApplicationAgreement.fulfilled]: (state, { payload }) => {
      state.application.introducerDeclarationAgree = payload?.data?.data?.introducerDeclarationAgree;
    },
    [updateApplicationAgreement.rejected]: (state) => {
      state.application.introducerDeclarationAgree = false;
    },
    [sendDisclosure.pending]: (state) => {
      state.disclosureLoader = true;
    },
    [sendDisclosure.fulfilled]: (state, { payload }) => {
      state.disclosureLoader = false;
    },
    [sendDisclosure.rejected]: (state) => {
      state.disclosureLoader = false;
    },
    [updateNccpDeclaration.pending]: (state) => { },
    [updateNccpDeclaration.fulfilled]: (state, { payload }) => {
      const updatedUser = payload?.data?.data || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === updatedUser?._id);
      state.application.customers[findindex].customerDisclaimer = updatedUser?.customerDisclaimer;
     },
    [updateNccpDeclaration.rejected]: (state) => { },
    [customerLogin.fulfilled]: (state, { payload }) => {
      state.errorMessage = null;
      localStorage.token = payload.data.data.token;
      // state.isUserContact = true
      // state.isCustomerLogin = true
      state.application = {
        customers: [payload.data.data?.customer]
      }
    },
    [customerLogin.pending]: (state) => {
      state.isFetching = true;
    },
    [customerLogin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [sendCustomerDetailsLinkToCustomer.fulfilled]: (state, { payload }) => {
      const updatedUser = payload?.data?.data || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === updatedUser?._id);
      state.application.customers[findindex] = updatedUser;
    },
    [saveCustomerDetails.fulfilled]: (state, { payload }) => {
      const updatedUser = payload?.data?.data || [];
      const data = current(state.application.customers);
      let findindex = data.findIndex((el) => el?._id === updatedUser?._id);
      state.application.customers[findindex] = updatedUser;
    },
    [getCloneApplication.fulfilled]: (state, { payload }) => {
      state.cloneApplicationDetails = payload.data;
      state.gettingClonedApplication = false;
    },
    [getCloneApplication.pending]: (state) => {
      state.gettingClonedApplication = true;
    },
    [getCloneApplication.rejected]: (state, { payload }) => {
      state.gettingClonedApplication = false;
      // state.errorMessage = payload.message;
    },
    [getConnectiveDocsTemplates.fulfilled]: (state, { payload }) => {
      state.templateDocuments = payload.data.data;
      state.gettingDocumentSelections = false;
    },
    [getConnectiveDocsTemplates.pending]: (state, { payload }) =>{
      state.gettingDocumentSelections = true;
    },
    [getConnectiveDocsTemplates.rejected]: (state, { payload }) =>{
      state.gettingDocumentSelections = false;
    },
  },
});

export const {
  saveValue,
  saveAsset,
  saveLoanDetails,
  clearState,
  updateLiabilitiesList,
  updateIncomeList,
  updateAssetList,
  updateExpensesList,
  updateAgeOfAsset,
  clearUpFinances,
  updateGlassAsset,
  saveCustomer,
  verifiedDialogBox,
  setRequiredFieldsError,
  getCurrentProductLenderId,
  setRequiredFieldsErrors,
  singleEntity,
  setValidationRequireFieldError,
  setCalculatorFieldsError,
  setNewCommercialCreditFile,
  setNewCustomerCreditFile,
  setNewIllionCreditFile,
  setNewCustomerBankStatementFile,
  setNewDocumentFile,
  setNewAssetPPSRFile,
  setNewActivityLog,
  setNewServiceUsageLog,
  updateCustomerData,
} = formdataSlice.actions;

export const store = configureStore({
  reducer: {
    userDetails: formdataSlice.reducer,
  },
});

export const stateSelector = (state) => state;
export const userDetailsSelector = (state) => state.userDetails;
export const applicationSelector = (state) => state.userDetails.application;
export const applicationCommentsSelector = (state) => state.userDetails.applicationComments;
export const activityLogSelector = (state) => state.userDetails.activityLog;
export const assetSelector = (state) => state.userDetails.application.asset;
export const loanDetailsSelector = (state) => state.userDetails.application.loanDetails;
export const entitySelector = (state) => state.userDetails.application?.entities;
export const customersSelector = (state) => state.userDetails.application.customers;
