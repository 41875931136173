import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch, reusableWithoutTokenFetch } from "../../utils/useAxios";

export const getUser = createAsyncThunk("user", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`user`, "GET", null, rejectWithValue);
  return response;
});

export const connectiveLogin = createAsyncThunk("connectiveLogin", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`user/connective/`, "POST", {}, rejectWithValue);
  return response;
});

export const registerUser = createAsyncThunk("registeruser", async (data, { rejectWithValue }) => {
  const response = await reusableWithoutTokenFetch(`user/register/`, "POST", data, rejectWithValue);
  return response;
});

export const updateUser = createAsyncThunk("user/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`user/`, "POST", data, rejectWithValue);
  return response;
});

export const loginUser = createAsyncThunk("login/user", async (data, { rejectWithValue }) => {
  const response = await reusableWithoutTokenFetch(`user/login/`, "POST", data, rejectWithValue);
  return response;
});

export const passwordLessLogin = createAsyncThunk("passwordlesslogin/user", async (data, { rejectWithValue }) => {
  const response = await reusableWithoutTokenFetch(`user/login-passwordless/`, "POST", data, rejectWithValue);
  console.log("response", response);
  return response;
});

export const forgotPassword = createAsyncThunk("forgotpassword/user", async ({ email }, { rejectWithValue }) => {
  const response = await reusableWithoutTokenFetch(
    `user/forget_password/`,
    "POST",
    JSON.stringify({
      email,
    }),
    rejectWithValue,
  );
  return response;
});

export const updatePassword = createAsyncThunk(
  "updatepassword/user",
  async ({ email, password }, { rejectWithValue }) => {
    const response = await reusableFetch(
      `user/forget_password/`,
      "POST",
      {
        email,
        password,
      },
      rejectWithValue,
    );
    return response;
  },
);

export const validateToken = createAsyncThunk("validate", async (token, { rejectWithValue }) => {
  const response = await reusableFetch(`user/validate`, "GET", rejectWithValue);
  return response?.data;
});

export const validateEmail = createAsyncThunk("validate/email", async (email, { rejectWithValue }) => {
  const response = await reusableWithoutTokenFetch(`user/email/verify/${email}`, "GET", rejectWithValue);
  return response;
});

export const requestResetPassword = createAsyncThunk("reset-password/request", async (email, { rejectWithValue }) => {
  const response = await reusableWithoutTokenFetch(
    `user-credentials/reset-password/request/${email}`,
    "GET",
    rejectWithValue,
  );
  return response;
});

export const resetPassword = createAsyncThunk("reset-password/reset", async (data, { rejectWithValue }) => {
  const response = await reusableWithoutTokenFetch(
    `user-credentials/reset-password/reset`,
    "POST",
    data,
    rejectWithValue,
  );
  return response;
});

export const updatePhoneNumber = createAsyncThunk("user/update-phoneNumber", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`user/phone-number`, "POST", data, rejectWithValue);
  return response;
});

export const changePassword = createAsyncThunk(
  "user-credentials/change-password",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(`user-credentials/change`, "POST", data, rejectWithValue);
    return response;
  },
);

export const updateSettings = createAsyncThunk("settings/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`settings`, "POST", data, rejectWithValue);
  return response;
});

export const sendEnquiry = createAsyncThunk("settings/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`user/enquiry`, "POST", data, rejectWithValue);
  return response;
});

export const getAllTransactions = createAsyncThunk("transactions/get", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`transactions/all`, "GET", rejectWithValue);
  return response;
});

export const createSubscription = createAsyncThunk("subscription/create", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`billing/subscription`, "POST", data, rejectWithValue);
  return response;
});

export const getCustomerBilling = createAsyncThunk("customer-billing/get", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`billing/customer`, "GET", rejectWithValue);
  return response;
});

export const getAllUsers = createAsyncThunk("all/get", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`user/all`, "POST", data, rejectWithValue);
  return response;
});

export const getAllRoleDetail = createAsyncThunk("all/getAllRoleDetail", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation-permission`, "GET", rejectWithValue);
  return response?.data;
});

export const getRoleById = createAsyncThunk("user/getRoleById", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation-permission/${data?._id}`, "GET", rejectWithValue);
  return response?.data;
});

export const createLenderSetting = createAsyncThunk("user/createLenderSetting", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`lender-settings`, "POST", data, rejectWithValue);
  return response;
});

export const updateLenderSetting = createAsyncThunk("user/updateLenderSetting", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`lender-settings`, "PUT", data, rejectWithValue);
  return response;
});

export const deleteLenderSetting = createAsyncThunk("user/deleteLenderSetting", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`lender-settings`, "DELETE", data, rejectWithValue);
  return response;
});

export const addPaymentCredit = createAsyncThunk("user/addPaymentCredit", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`user-payment-method/add-payment-method`, "POST", data, rejectWithValue);
  return response?.data;
});

export const DeletePaymentCredit = createAsyncThunk("user/DeletePaymentCredit", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`user-payment-method/delete-payment-method`, "POST", data, rejectWithValue);
  return response?.data;
});

export const getPaymentMethod = createAsyncThunk("user/getPaymentMethod", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`user-payment-method/get-payment-methods`, "GET", data, rejectWithValue);
  return response?.data;
});

export const getServicesCharges = createAsyncThunk("user/getServicesCharges", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`service-usage/get-service-usage`, "POST", data, rejectWithValue);
  return response?.data;
});

export const getServicesDataExport = createAsyncThunk(
  "user/getServicesUsageExport",
  async ({ url }, { rejectWithValue }) => {
    const response = await reusableFetch(
      `service-usage/service-usage-report${url}`,
      "GET",
      {},
      rejectWithValue,
      true,
    );

    return response?.data;
  },
);

export const getServicesDetail = createAsyncThunk("user/getServicesDetail", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(
    `service-usage/get-service-usage-details?serviceId=${data?.serviceId}&fromDate=${data?.fromDate || ""}&toDate=${data?.toDate || ""}`,
    "GET",
    rejectWithValue,
  );
  return response?.data;
});

export const getInVoiceList = createAsyncThunk("user/getInVoiceList", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`Invoice/get-invoice-details`, "POST", data, rejectWithValue);
  return response?.data;
});

export const getInVoiceDetail = createAsyncThunk("user/getInVoiceDetail", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`Invoice/get-invoice-data/${data?.serviceId}`, "GET", rejectWithValue);
  return response?.data;
});

export const getInVoiceDownloadUrl = createAsyncThunk(
  "user/getInVoiceDownloadUrl",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(`Invoice/invoice-download/${data?.serviceId}`, "GET", rejectWithValue);
    return response?.data;
  },
);

export const submitTerms = createAsyncThunk("user/terms", async (data, { rejectWithValue, getState }) => {
  const privacy = await reusableFetch(`user/terms`, "POST", data, rejectWithValue);
  return privacy;
});

export const updateUserRole = createAsyncThunk("user/updateUserRole", async ({ userId, data }, { rejectWithValue }) => {
  const response = await reusableFetch(`user-permission/${userId}`, "PUT", data, rejectWithValue);
  return response?.data;
});

export const saveRolePermissions = createAsyncThunk(
  "user/saveRolePermissions",
  async ({ data, id }, { rejectWithValue }) => {
    const response = await reusableFetch(`organisation-permission/${id}`, "PUT", data, rejectWithValue);
    return response?.data;
  },
);

export const getOrganisationUser = createAsyncThunk(
  "user/getOrganisationUser",
  async ({ cursor, limit }, { rejectWithValue }) => {
    const response = await reusableFetch(`user-permission?cursor=${cursor}&limit=${limit}`, "GET", rejectWithValue);
    return response?.data;
  },
);

export const getOrganisationSetting = createAsyncThunk(
  "user/getOrganisationSetting",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(`organisation-setting/get-organisation-setting`, "GET", rejectWithValue);
    return response?.data?.data;
  },
);

export const updateOrganisationSetting = createAsyncThunk(
  "user/updateOrganisationSetting",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `organisation-setting/update-organisation-setting`,
      "POST",
      data,
      rejectWithValue,
    );
    return response?.data?.data;
  },
);

const initialState = {
  emailInUse: false,
  isCreatingAccount: false,
  createAccountSuccess: false,
  isPasswordLessLoginNewUser: false,
  changePasswordFail: null,
  isChangingPassword: null,
  changePasswordSuccess: false,
  templateSaveSuccess: false,
  transactions: null,
  clientSecret: null,
  passwordLessLoginSuccess: false,
  isCreatingSubscription: false,
  getAllPayments: [],
  servicesCharges: [],
  servicesDetail: [],
  inVoiceList: [],
  inVoiceDetail: [],
  is_service_paid: null,
  userRoleList: [],
  is_service_enable: false,
  servicesChargesLoader: false,
  userRoleLoader: false,
  roleById: [],
  oraganisationUser: [],
  getRoleByIdLoader: false,
  inVoiceListLoader: false,
  inVoiceDetailLoader: false,
  serviceDetailLoader: false,
  exportLoader: false,
  all: {},
  users: {
    docs: [],
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
    resetState: () => initialState,
  },
  extraReducers: {
    [getInVoiceDownloadUrl.fulfilled]: (state, { payload }) => {
      state.url = payload?.data?.invoice_pdf || payload.data.url;
    },
    [getInVoiceDownloadUrl.pending]: (state) => {},
    [getInVoiceDownloadUrl.rejected]: (state) => {},
    [getServicesCharges.fulfilled]: (state, { payload }) => {
      state.servicesCharges = payload?.data;
      state.servicesChargesLoader = false;
    },
    [getServicesCharges.pending]: (state) => {
      state.servicesCharges = [];
      state.servicesChargesLoader = true;
    },
    [getServicesCharges.rejected]: (state) => {
      state.servicesCharges = [];
      state.servicesChargesLoader = false;
    },

    [getServicesDetail.fulfilled]: (state, { payload }) => {
      state.servicesDetail = payload?.data;
      state.serviceDetailLoader = false;
    },
    [getServicesDetail.pending]: (state) => {
      state.servicesDetail = [];
      state.serviceDetailLoader = true;
    },
    [getServicesDetail.rejected]: (state) => {
      state.servicesDetail = [];
      state.serviceDetailLoader = false;
    },

    [getInVoiceList.fulfilled]: (state, { payload }) => {
      state.inVoiceList = payload?.data;
      state.inVoiceListLoader = false;
    },
    [getInVoiceList.pending]: (state) => {
      state.inVoiceListLoader = true;
      state.inVoiceList = [];
    },
    [getInVoiceList.rejected]: (state) => {
      state.inVoiceList = [];
      state.inVoiceListLoader = false;
    },

    [getInVoiceDetail.fulfilled]: (state, { payload }) => {
      state.inVoiceDetail = payload?.data;
      state.inVoiceDetailLoader = false;
    },
    [getInVoiceDetail.pending]: (state) => {
      state.inVoiceDetail = [];
      state.inVoiceDetailLoader = true;
    },
    [getInVoiceDetail.rejected]: (state) => {
      state.inVoiceDetail = [];
      state.inVoiceDetailLoader = false;
    },

    [getServicesDataExport.fulfilled]: (state, { payload }) => {
      // console.log("payload?.data",payload)
      // state.exportLoader = false;
    },
    [getServicesDataExport.pending]: (state) => {
      state.exportLoader = true;
    },
    [getServicesDataExport.rejected]: (state) => {
      state.exportLoader = false;
    },

    [updateOrganisationSetting.fulfilled]: (state, { payload }) => {
      state.is_service_paid = payload?.service_invoice_paid_by;
    },
    [updateOrganisationSetting.pending]: (state) => {},
    [updateOrganisationSetting.rejected]: (state) => {},

    [getOrganisationSetting.fulfilled]: (state, { payload }) => {
      state.is_service_paid = payload?.service_invoice_paid_by;
    },
    [getOrganisationSetting.pending]: (state) => {},
    [getOrganisationSetting.rejected]: (state) => {},

    [getPaymentMethod.fulfilled]: (state, { payload }) => {
      state.getAllPayments = payload?.data;
    },
    [getPaymentMethod.pending]: (state) => {},
    [getPaymentMethod.rejected]: (state) => {
      state.getAllPayments = [];
    },

    [addPaymentCredit.fulfilled]: (state, { payload }) => {},
    [addPaymentCredit.pending]: (state) => {},
    [addPaymentCredit.rejected]: (state) => {},

    [DeletePaymentCredit.fulfilled]: (state, { payload }) => {},
    [DeletePaymentCredit.pending]: (state) => {},
    [DeletePaymentCredit.rejected]: (state) => {},

    [getUser.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data.data);
    },
    [getUser.pending]: (state) => {
      state.isUpdating = true;
    },
    [getUser.rejected]: (state) => {
      state.isCreatingAccount = false;
    },

    [getAllUsers.fulfilled]: (state, { payload }) => {
      state.users = payload?.data?.data;
    },
    [getAllUsers.pending]: (state) => {
      state.isUpdating = true;
    },
    [getAllUsers.rejected]: (state) => {
      state.isCreatingAccount = false;
    },

    [getAllRoleDetail.fulfilled]: (state, { payload }) => {
      state.userRoleList = payload?.data;
      state.userRoleLoader = false;
    },
    [getAllRoleDetail.pending]: (state) => {
      state.userRoleList = [];
      state.userRoleLoader = true;
    },
    [getAllRoleDetail.rejected]: (state) => {
      state.userRoleList = [];
      state.userRoleLoader = false;
    },

    [getRoleById.fulfilled]: (state, { payload }) => {
      state.roleById = payload?.data;
      state.getRoleByIdLoader = false;
    },
    [getRoleById.pending]: (state) => {
      state.roleById = [];
      state.getRoleByIdLoader = true;
    },
    [getRoleById.rejected]: (state) => {
      state.roleById = [];
      state.getRoleByIdLoader = false;
    },

    [updateUser.fulfilled]: (state, { payload }) => {
      if (payload.data.data) state = _.merge(state, payload.data.data.user);
      state.isUpdating = false;
      state.updateSuccess = true;
    },
    [updateUser.pending]: (state) => {
      state.isUpdating = true;
    },
    [updateUser.rejected]: (state) => {
      state.isCreatingAccount = false;
    },

    [registerUser.fulfilled]: (state, { payload }) => {
      state.isCreatingAccount = false;
      localStorage.token = payload.data.data.token;
      state = _.merge(state, payload.data.data);
      state.createAccountSuccess = true;
    },
    [registerUser.pending]: (state) => {
      state.isCreatingAccount = true;
    },
    [registerUser.rejected]: (state) => {
      state.isCreatingAccount = false;
    },

    [passwordLessLogin.fulfilled]: (state, { payload }) => {
      state.errorMessage = null;
      localStorage.token = payload.data.token;
      state = _.merge(state, payload.data.user);
      state.passwordLessLoginSuccess = true;
      state.isPasswordLessLoginNewUser = payload.data.newUser;
    },
    [passwordLessLogin.pending]: (state) => {
      state.isFetching = true;
    },
    [passwordLessLogin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    // [getUserByEmail.fulfilled]: (state, { payload }) => {
    //   console.log({ payload })
    //   state.errorMessage = null
    //   localStorage.token = payload.data.token;
    //   state = _.merge(state, payload.data.user);
    //   state.isUser = true
    // },
    // [getUserByEmail.pending]: (state) => {
    //   state.isFetching = true;
    // },
    // [getUserByEmail.rejected]: (state, { payload }) => {
    //   state.isFetching = false;
    //   state.isError = true;
    //   state.errorMessage = payload.message;
    // },

    [connectiveLogin.fulfilled]: (state, { payload }) => {
      state.errorMessage = null;
      // console.log("payload.data", payload.data)
      localStorage.token = payload.data.data.token;
      if (payload.data.data?.illionSubscriberId) {
        sessionStorage.illionSubscriberId = payload.data.data.illionSubscriberId;
        sessionStorage.illionUserKey = payload.data.data.illionUserKey;
        sessionStorage.illionPassword = payload.data.data.illionPassword;
      }
      state = _.merge(state, payload.data.data.user);
      state.isUser = true;
    },
    [connectiveLogin.pending]: (state) => {
      state.isFetching = true;
    },
    [connectiveLogin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.errorMessage = null;
      localStorage.token = payload.data.data.token;
      state = _.merge(state, payload.data.data.user);
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [forgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [forgotPassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [updatePassword.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload?.email;
      state.successMessage = payload.message;
    },
    [updatePassword.pending]: (state) => {
      state.isFetching = true;
    },
    [updatePassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [updatePhoneNumber.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      state = _.merge(state, payload.data.data);
    },
    [updatePhoneNumber.pending]: (state) => {
      state.isFetching = true;
    },
    [updatePhoneNumber.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [validateToken.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state = _.merge(state, payload?.data);
    },
    [validateToken.pending]: (state) => {
      state.isFetching = true;
    },
    [validateToken.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [validateEmail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.emailInUse = payload.data.data.emailInUse;
    },
    [validateEmail.pending]: (state) => {
      state.isFetching = true;
    },
    [validateEmail.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [requestResetPassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [requestResetPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [requestResetPassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [resetPassword.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      localStorage.token = payload.data.data.token;
      state = _.merge(state, payload.data.data.user);
    },
    [resetPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [resetPassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [changePassword.fulfilled]: (state, { payload }) => {
      localStorage.token = payload.data.data.token;
      state.isChangingPassword = false;
      state.changePasswordSuccess = true;
      state = _.merge(state, payload.data.data.user);
    },
    [changePassword.pending]: (state) => {
      state.isChangingPassword = true;
      state.changePasswordFail = true;
    },
    [changePassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isChangingPassword = false;
      state.changePasswordFail = true;
    },

    [updateSettings.fulfilled]: (state, { payload }) => {
      localStorage.token = payload.data.data.token;
      state.isChangingPassword = false;
      state.changePasswordSuccess = true;
      state = _.merge(state, payload.data);
      state.templateSaveSuccess = true;
    },
    [updateSettings.pending]: (state) => {
      state.isChangingPassword = true;
      state.changePasswordFail = true;
    },
    [updateSettings.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isChangingPassword = false;
      state.changePasswordFail = true;
    },

    [getAllTransactions.fulfilled]: (state, { payload }) => {
      state = _.merge(state.transactions, payload.data.data);
    },
    [getAllTransactions.pending]: (state) => {
      state.isChangingPassword = true;
    },
    [getAllTransactions.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [createSubscription.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data.data);
      state.isCreatingSubscription = false;
    },
    [createSubscription.pending]: (state) => {
      state.isChangingPassword = true;
      state.isCreatingSubscription = true;
    },
    [createSubscription.rejected]: (state) => {
      state.isCreatingSubscription = false;
    },

    [getCustomerBilling.fulfilled]: (state, { payload }) => {
      state.isCreatingSubscription = false;
      state = _.merge(state, payload.data.data);
    },
    [getCustomerBilling.pending]: (state) => {
      state.isChangingPassword = true;
      state.isCreatingSubscription = true;
    },
    [getCustomerBilling.rejected]: (state) => {
      state.isCreatingSubscription = false;
    },

    [createLenderSetting.pending]: (state) => {
      state.lenderSettings = [];
    },
    [createLenderSetting.rejected]: (state) => {
      state.lenderSettings = [];
    },
    [createLenderSetting.fulfilled]: (state, { payload }) => {
      state.lenderSettings = payload?.data?.data;
    },

    // [updateLenderSetting.pending]: (state) => {},
    // [updateLenderSetting.rejected]: (state) => {},
    // [updateLenderSetting.fulfilled]: (state, { payload }) => {
    // state.lenderSettings = payload.data;
    // },

    [deleteLenderSetting.pending]: (state) => {},
    [deleteLenderSetting.rejected]: (state) => {},
    [deleteLenderSetting.fulfilled]: (state, { payload }) => {
      state.lenderSettings = payload.data.data;
    },

    [submitTerms.fulfilled]: (state, { payload }) => {
      if (payload.data.data) state = _.merge(state, payload.data.data.user);
      state.isUpdating = false;
      state.updateSuccess = true;
    },
    [submitTerms.pending]: (state) => {
      state.isUpdating = true;
    },
    [submitTerms.rejected]: (state) => {
      state.isCreatingAccount = false;
    },
    [updateUserRole.pending]: (state) => {},
    [updateUserRole.rejected]: (state) => {},
    [updateUserRole.fulfilled]: (state, { payload }) => {
      // state.lenderSettings = payload.data.data;
    },
    [saveRolePermissions.pending]: (state) => {},
    [saveRolePermissions.rejected]: (state) => {},
    [saveRolePermissions.fulfilled]: (state, { payload }) => {
      // state.lenderSettings = payload.data.data;
    },
    [getOrganisationUser.pending]: (state) => {
      state.oraganisationUser = [];
    },
    [getOrganisationUser.rejected]: (state) => {
      state.oraganisationUser = [];
    },
    [getOrganisationUser.fulfilled]: (state, { payload }) => {
      state.oraganisationUser = payload?.data;
    },
  },
});

export const { saveValue, resetState } = userSlice.actions;

export const userSelector = (state) => state.user;
